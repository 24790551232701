.auth,
.tcf-v2 {
  background-color: #fff;

  p {
    color: #969696;
  }

  .logo {
    img {
      width: 185px;
    }
  }

  .ory-fields-container {
    padding: 60px 60px !important;

    .title-container p {
      color: #969696;
    }

    .social-sign-in-container {
      button {
        text-transform: none;
        font-size: 16px;
        border: 1px solid #E6E8E7;
        border-radius: 25px;
        color: #000;

        &.icon {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          margin-right: 5px;

        }
      }
    }
  }

  .divider {
    color: #969696;
  }

  .ory-helper-text {
    font-family: 'MONTSERRAT';
    font-size: 14px;
  }

  .ory-input-fields {

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }

    .forgot-password {
      a {
        font-size: 14px;
        color: #5551FF;
      }
    }

    .ory-submit-button {
      background-color: #5551FF;
      border-radius: 70px;
      font-size: 18px;
      text-transform: none;
      padding: 8px 0;

      &:disabled {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        color: #b5b5b5;
        pointer-events: visible;
        cursor: not-allowed;
      }
    }

    .need-account {
      p {
        font-size: 16px;
      }

      a {
        color: #5551FF;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }

  .image-container {
    height: 100vh;
    padding-left: 0px;

    .main-image {
      object-fit: cover;
      width: 100%;
      border-radius: 24px;
      padding: 12px;
      height: 100vh;
      object-position: bottom;

      &.brightness-50 {
        filter: brightness(0.5);
      }
    }
  }
}

.tcf-border-dashed {
  border: 1px dashed rgb(100, 100, 100);
  border-radius: 12px;

  h2 {
    color: rgba(0, 0, 0, 0.527);
  }
}

.tcf-button-small {
  background-color: #5551FF !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 8px 20px !important;

  &.outlined {
    background-color: #fff !important;
    border: 1px solid #5551FF;
    color: #5551FF;
    box-shadow: none;
  }
}

.tcf-older-button-small {
  background-color: #3f2567 !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 8px 20px !important;
  height: 30px;

  &.outlined {
    background-color: #fff !important;
    border: 1px solid #3f2567;
    color: #3f2567;
    box-shadow: none;
 
  }
.signUP{
  background-color: rgb(85, 81, 255) !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 8px 20px !important;
  
}

  .login {
    background-color: #fff !important;
    color: rgb(85, 81, 255);
    border-radius: 25px !important;
    font-size: 14px !important;
    text-transform: none !important;
    padding: 8px 20px !important;
    
  }

  &:disabled {
    color: #f9f7f7 !important;
    background-color: #3f2567c4 !important;
  }
}

.tcf-icon {
  margin-top: -5px;

  &.red {
    color: #DC362E;
  }
}

.tcf-congrats-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}


  .login.mobile {
    padding: 10px 60px !important;
 
    border: 1px solid rgb(85, 81, 255);
    border-radius: 25px;
    text-wrap: nowrap;
    font-size: 10px !important;
    background-color: rgb(85, 81, 255) !important;
    color: #fff;
  }
  .signUP.mobile{
    padding: 10px 60px !important;
   
    text-wrap: nowrap;
    font-size: 10px !important;
    background-color:  #fff !important;
    color:rgb(85, 81, 255)
  }
