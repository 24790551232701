@import "./styles/auth.scss";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap";
/*generic globals*/
body {
  background: #f5f5f5;
  font-family: "Montserrat", sans-serif;
  position: relative;
}
body.modalOpen {
  height: 100vh;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #403f3f;
}
h1 {
  font-size: 40px;
  font-weight: 700;
}
h2 {
  font-size: 30px;
  font-weight: 700;
}
h3 {
  font-size: 25px;
  font-weight: 600;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
  font-weight: 700;
}
h6 {
  font-size: 18px;
  font-weight: 600;
}
p {
  color: #031029cc;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  font-weight: 400;
}
b {
  font-weight: 600;
}
strong {
  font-weight: 700;
}
img {
  max-width: 100%;
  height: auto;
}
button:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}
a:hover {
  cursor: pointer;
  text-decoration: none;
}
button:hover,
.btn:hover {
  opacity: 0.8;
}
label {
  font-weight: normal;
  font-size: 16px;
  color: #3f2566;
  margin-bottom: 0;
}
a:hover {
  text-decoration: none;
}
.btn {
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}
.primaryBtn {
  background-color: #3f2566;
  border: 1px solid #3f2566;
  color: #fff;
}
.primaryBtn:hover {
  color: #fff;
}
.secondaryBtn {
  background-color: #fff;
  border: 1px solid #3f2566;
  color: #3f2566;
}
.defaultBtn {
  background-color: #f9b316;
  border: 1px solid #f9b316;
  color: #fff;
}
.defaultLogo {
  width: 200px; 
  height: 200px; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f2566; 
  color: white; 
  font-size: 2rem; 
  border-radius: 50%; 
}

.centerBtn {
  display: block;
  margin: 0 auto;
}
.defaultBtn:hover {
  background-color: #3f2566;
  border: 1px solid #3f2566;
  color: #fff;
}
.checkboxLabel input[type="checkbox"] {
  margin-right: 5px;
}
.checkboxGroup > label {
  display: inline-flex;
  margin-right: 20px;
}
.checkboxGroup > label input {
  margin-top: 3px;
  margin-right: 5px;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}
.filter-top{
  display: flex;
  align-items: self-start;
  flex-wrap: nowrap;
  font-size: small !important;
  justify-content: space-evenly;
  background-color: rgb(255,255,255);
  padding-top: 6px;
 
}
.filter-top img{
  margin-top: 3px;
}
.filter-container {
  position: relative;
}

.blurred {
  filter: blur(50px);

}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.blur-overlay p {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  color: #000;
  border-radius: 5px;
  background-color: red;
}

/* .checkboxLabel input[type="checkbox"]{position:relative;}
.checkboxLabel input[type="checkbox"]:checked{opacity:0;}
.checkboxLabel input[type="checkbox"]::before{content:"";position:absolute;left:0;top:0;background:url("/src/static/images/unchecked.svg");background-repeat:no-repeat;background-position:center;width:15px;height:15px;}
.checkboxLabel input[type="checkbox"]:checked::before{background:url("/src/static/images/checked.svg");} */

/* .primaryBtn:hover{color:#3F2566;background-color:#fff;}
.secondaryBtn:hover{background-color:#3F2566;color:#fff;} */
.MuiAlert-root {
  padding: 0px 10px !important;
  font-size: 14px !important;
}
.MuiAlert-icon {
  margin-right: 5px !important;
}
.noRecP {
  text-align: center;
  font-size: 18px;
  color: #606060;
  font-weight: 400;
}

/*----------------------form fields globals globals-------------*/
.form-control {
  font-weight: normal;
  padding: 9px 10px 9px 30px;
  border: 2px solid #3f2566;
  border-radius: 4px;
  color: #031029;
  font-size: 14px;
  height: 45px;
  color: #031029;
  background: #e9defa;
}
.inputWrap input[type="number"]::-webkit-outer-spin-button,
.inputWrap input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  appearance: none;
}
.cluster div {
  color: white !important;
  font-size: 17px !important;
  font-weight: normal !important;
  line-height: 56px !important;
}

/* Firefox */
.inputWrap input[type="number"] {
  -moz-appearance: textfield;
}
#map .gm-style .gm-style-iw-c {
  top: -16px !important;
}
#map .gm-ui-hover-effect {
  display: none !important;
}
#map .gm-style .gm-style-iw-t::after {
  top: -16px !important;
}
input::placeholder,
.form-control::placeholder {
  color: #03102980;
}
textarea.form-control {
  resize: none;
  height: 144px;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #3f2566;
  background: #e9defa;
}
.MuiFormControl-marginNormal {
  height: 45px;
  border: 2px solid #3f2566 !important;
  background-color: #e9defa;
  border-radius: 4px;
}
.css-1hvq1u5-control,
.css-1r4d67j-control,
.css-yk16xz-control,
.css-1pahdxg-control {
  border: 2px solid #3f2566 !important;
  background-color: #e9defa !important;
  height: 45px;
  box-shadow: none !important;
}
.css-1wa3eu0-placeholder {
  font-size: 14px;
  font-weight: 400;
}
.css-14jk2my-container {
  border: 2px solid #3f2566;
  border-radius: 4px;
}
.css-ky9w75-singleValue,
.MuiInputBase-input {
  font-size: 14px !important;
  color: #031029 !important;
  font-weight: 400 !important;
}
.css-g1d714-ValueContainer,
.css-g1d714-ValueContainer,
.css-1hwfws3 {
  padding-left: 30px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #e9defa inset !important;
}
.form-group {
  position: relative;
}
.formIcon {
  position: absolute;
  left: 8px;
  top: 13px;
  width: 18px;
}
/* .MuiOutlinedInput-adornedStart{padding-left:1px!important;} */
.react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
  font-size: 14px;
  height: 30px !important;
  color: #031029 !important;
  background: #e9defa !important;
  padding-left: 15px !important;
}
.react-tel-input {
  border: 2px solid #3f2566;
  border-radius: 4px;
}
.react-tel-input .flag-dropdown {
  left: 0px;
}

/*------------- header -----------*/
.header {
  background: #fff;
}
.header .logo img {
  width: 240px;
}
.betaTop{
    background-color: #F6F6FF;
    padding: 7px 0;
}

.betaText{
  font-size: 17px;
  line-height: 15px;
  font-weight: 500;
  padding-right: 3rem;
}

.btnBeta{
  font-size: 14px;
  color:white;
  font-weight: 500;
  background: linear-gradient(to right, #6A11CB, #2575FC);
  padding: 10px 15px;
  border-radius: 75px;
  margin-right: 4px;
  border: none;
  width: 100%;
}
.betaIcon{
  width: 60px ;
  height: 60px;
}

.betaGradient {
  background: -webkit-linear-gradient(left, #6A11CB, #2575FC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #6A11CB; /* Fallback for browsers that don't support gradients */
}

@media (max-width: 768px) {
  /* .betaTop {
      display: none;
  } */
  .btnBeta{
    width: 120px;
    font-size: 10px !important;
  }
  .betaIcon{
    width: 40px ;
    height: 40px;
  }
  .betaText{
    font-size: 10px !important;
    line-height: 12px;
    font-weight: 400;
    padding-right: 1rem;
  }
}

.headTop {
  background-color: #3f2566;
  padding: 10px 0;
}

.headTopNav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
}
.headTopNav ul li {
  display: inline-block;
  margin: 0 5px;
}
.headTopNav ul li a,
.headTopNav ul li button {
  color: #fff;
  font-size: 15px;
  background-color: transparent;
  border: 0;
}
.headTopNav ul li a img,
.headTopNav ul li button img {
  display: none;
}
.headTopNav ul li a:hover,
.headTopNav ul li button:hover {
  text-decoration: underline;
}
.headTopNav .logo {
  display: none;
}
.closeTopNav,
.openTopNav {
  display: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.headTopFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headTopFlex .left a {
  position: relative;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  margin-right: 35px;
}
.headTopFlex .left a .fa {
  margin-right: 5px;
  font-size: 18px;
}
.topSearch .button {
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  position: relative;
  border: none;
  padding: 0;
}
.topSearch .button img {
  position: absolute;
  width: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.topSearch .button:hover,
.topSearch .button[aria-expanded="true"] {
  background-color: #e9defa;
}
.topSearch {
  display: inline-block;
  margin-right: 20px;
}
.topSearch .dropdown-menu {
  box-shadow: none !important;
  border-radius: 2px;
  right: -4px !important;
  left: auto !important;
  transform: none !important;
  top: 54px !important;
  padding: 10px;
  border: 1px solid #3f25666b !important;
}
.topSearch .dropdown-menu .topShape {
  position: absolute;
  top: -15px;
  right: 10px;
  width: 23px;
}
.topSearch .dropdown-menu:before {
  display: none !important;
}
.accBtn {
  padding: 0;
  border: none;
  background: transparent;
  text-align: center;
}
.accBtn img {
  width: 35px;
  margin: 0 auto -5px;
  display: block;
}
.accBtn span {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #3f2566;
}
.headBottom .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dropInner {
  box-shadow: 0 0 5px 2px #dadada;
}
.sitemapScroll {
  max-height: 300px;
  overflow-y: auto;
}
.dropInner .searchWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
}
.dropInner .searchWrap button {
  background: #3f2566;
  border: 1px solid #3f2566;
  flex-shrink: 0;
  padding: 5px 10px;
  display: inline-flex;
  border-radius: 0 4px 4px 0;
  margin: 0;
}
.dropInner .searchWrap input {
  background-color: #e6e6e6;
  padding: 5px 7px;
  height: 33px;
  border: 1px solid #3f2566;
  border-radius: 4px 0 0 4px;
}
.dropInner .searchWrap button img {
  width: 17px;
}
.searchSecBox {
  padding: 10px;
}
.searchSecBox p.tag {
  display: inline-block;
  border: 1px solid #f9b316;
  background-color: #faeebd;
  padding: 5px 10px;
  border-radius: 13px;
  line-height: 1;
  margin-bottom: 10px;
}
.searchSecBox ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.searchSecBox ul li {
}
.searchSecBox ul li a {
  color: #000;
  font-size: 14px;
}
.searchSecBox a.showBtn {
  color: #3f2566;
  text-decoration: underline;
  font-size: 14px;
}
.headPopup .content {
  background: #fffffe;
  color: rgb(14, 13, 13);
  border: 0px solid #f9b316;
  padding: 5px 14px;
  border-radius: 5px;
  font-size: 10px;
}

/* .headTopFlex .left a:nth-of-type(1):after{content:"|";position:absolute;font-size:17px;color:#fff;right:-17px;top:50%;transform:translate(0,-50%);} */
ul.socialIcons {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.socialIcons li {
  display: inline-block;
  margin-left: 35px;
}
ul.socialIcons li a {
  font-size: 16px;
  color: #fff;
  position: relative;
}
ul.socialIcons li a:after {
  content: "|";
  position: absolute;
  font-size: 20px;
  color: #fff;
  left: -17px;
  top: 50%;
  transform: translate(0, -50%);
}
ul.socialIcons li:nth-of-type(1) a:after {
  display: none;
}
ul.socialIcons li:nth-of-type(1) {
  margin-left: 0;
}
ul.socialIcons li a:hover {
  color: #f9b316;
}
.headBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  position: relative;
}

/*.headBottom{display:flex;justify-content:space-between;align-items:center;position:relative;}*/
.headBottom .middle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.headBottom .middle .searchBtn {
  padding: 0;
  background: transparent;
  border: none;
  position: relative;
  margin-left: 28px;
  color: #4e4e4e;
}
.headBottom .middle .searchBtn:hover {
  color: #f9b316;
}
.headBottom .middle .searchBtn:after {
  content: "|";
  position: absolute;
  font-size: 23px;
  color: #4e4e4e;
  left: -19px;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 100;
}
.headBottom .right .btn {
  width: 85px;
  margin-left: 20px;
  padding: 3px 5px;
}
.headBottom .right .btn:nth-of-type(1) {
  margin-left: 0;
}
.headBottom .right .btn:hover {
  color: #fff;
  background-color: #f9b316;
  border-color: #f9b316;
}
.headTopFlex .left a:hover {
  color: #f9b316;
}
.main-menu-div > ul > li > a img,
.main-menu-div > ul > li > button img {
  display: block;
  margin: 0 auto 5px;
  width: 35px;
}
.css-1uccc91-singleValue {
  color: #fff !important;
}

/*--------------top search modals--------*/
.modalTagWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.modalTagWrap .btn {
  font-size: 14px;
  padding: 1px 15px;
  margin-bottom: 10px;
}
.modalTagWrap .searchTag {
  margin-bottom: 10px;
  font-size: 14px;
}
.regionFlex h5 {
  font-size: 18px;
  font-weight: 600;
}
.regionFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.regionModal .countrySearchWrap .form-control {
  background-color: #e9defa;
  border-radius: 4px;
}
.regionModal .modal-dialog {
  max-width: 1190px;
}
.regionFlex .left li {
  display: block;
  margin: 1px 0;
}
.regionFlex .left button.disciplines_course {
  display: block;
  margin: 1px 0;
  border: 0;
  background-color: transparent;
  width: 100%;
}
.regionFlex .left button.disciplines_course:hover {
  border: 0;
  background-color: transparent;
  width: 100%;
}
.regionFlex .left {
  width: 260px;
  flex-shrink: 0;
}
.regionFlex .right {
  flex: 1 1 auto;
}
.regionFlex ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.regionFlex ul li a {
  color: #666;
  font-size: 14px;
  word-break: break-word; /* border-radius: 4px; display: flex; justify-content: space-between; align-items: center;padding: 3px 5px; */
}

/* .regionFlex ul li a:hover, .regionFlex ul li a:hover a{background: #291C5A; color: #fff;} */
.regionFlex .right ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.regionFlex .right ul li {
  width: 21%;
  margin: 4px 14px;
}
.regionFlex.regionCourseFlex .right ul li {
  width: 30%;
}
.regionModal .modal-header {
  background: #291c5a;
  align-items: center;
}
.regionModal .modal-header h5 {
  color: #fff;
  font-weight: 500;
  margin: 0;
}
.regionModal .close {
  padding: 0;
  color: #fff;
  opacity: 1;
  margin: 0;
}
.regionModal .close .fa {
}
.searchBackWrap {
  position: relative;
}
.regionModal .backBtn {
  color: #fff;
  margin-bottom: 20px;
  background: #f9b316;
  border: none;
  padding: 5px 10px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.regionModal .backBtn .fa {
  font-size: 20px;
  margin-right: 5px;
}

/*--------------banners--------*/
.marginBanner {
  margin-bottom: 50px;
}
.courseBanner {
  position: relative;
  margin-bottom: 50px;
}
.courseBanner > img {
  width: 100%;
}
.courseBanner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2c1d666b;
}
.courseBanner .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 20px;
  text-align: center;
}
.courseBanner h1 {
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 33px;
  margin-bottom: 30px;
  line-height: 41px;
}
.courseBanner h1 span {
  display: block;
  font-weight: 400;
  font-size: 28px;
}
.courseBanner h2 {
  color: #fff;
  text-align: center;
  font-weight: 400;
  margin-bottom: 30px;
}
.searchWrap {
  margin: 0 auto 0px;
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 5px;
}
.searchBox {
  position: relative;
  width: 30%;
  padding: 20px 5px 20px;
  border-right: 1px solid #e6e6e6;
}
.searchBtn {
  position: relative;
  width: 12%;
  padding: 4px 2px 4px;
}
.searchBox:nth-last-of-type(1) {
  border-right: 0;
}
.searchWrap1 {
  margin-bottom: 2px;
  padding-left: 20px;
  max-width: 290px;
}
.searchBox .unselectBtn {
  padding: 0;
  border: none;
  background: transparent;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}
.searchBox input.form-control {
  height: 35px;
  padding: 5px 20px 5px 15px;
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 300;
}
.searchBox input.form-control::placeholder {
  color: #bdbcbc9e;
  font-weight: 400;
}
.advFilter {
  text-align: right;
  margin: 0 auto 30px;
  width: 1000px;
}
.advFilter .btn {
  color: #fff;
}
.advFilter .btn img {
  width: 20px;
  margin-right: 8px;
}
.searchBox input.form-control::placeholder {
  color: #5a5a5a;
}
.searchBtn .btn {
  background: #fab316;
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  border-radius: 0px 5px 5px 0px;
  padding: 18px 20px;
}
.courseBoxTop button,
.twelveBox button {
  background: transparent;
  border: 0;
}
.cursor-pointer {
  cursor: pointer;
}
/* new css for search */
.searchBox .custom-dropdown {
  position: relative;
}
.searchBox .custom-dropdown .btnWrap {
  position: relative;
}
.searchBox .custom-dropdown .btnWrap .fa {
  color: #5a5a5a;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
}
.searchBox .custom-dropdown .custom-dropdown-menu {
  z-index: 9;
  display: none;
  background: #fff;
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0px 0px 3px 3px;
  border: none;
  box-shadow: 0 0 2px 2px #ececec;
  width: 100%;
  transform: none !important;
  top: 60px !important;
  left: 0 !important;
  padding: 6px 6px;
}
.searchBox .custom-dropdown .custom-dropdown-menu.show {
  display: block;
}
.searchBox h5.courseTag {
  color: #f9b316;
  border: 1px solid #f9b316;
  border-radius: 12px;
  font-size: 13px;
  display: inline-block;
  font-weight: 400;
  padding: 3px 10px;
  margin: 5px 10px;
}
.searchBox h5.locationTag {
  font-size: 13px;
  font-weight: 500;
  padding-top: 10px;
  margin: 5px 10px;
  border-top: 1px dashed #707070;
}
.searchBox .custom-dropdown-menu a {
  text-align: left;
  font-size: 14px;
  padding: 2px 10px;
  white-space: break-spaces;
}
.searchBox .custom-dropdown-menu a:active {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.searchBox .custom-dropdown-menu .card {
  border: none;
  border-radius: 0;
}
.searchBox .custom-dropdown-menu .accordBtn {
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
.searchBox
  .custom-dropdown-menu
  .accordBtn
  a[aria-expanded="true"]
  .fa-angle-down {
  display: inline-block;
}
.searchBox
  .custom-dropdown-menu
  .accordBtn
  a[aria-expanded="false"]
  .fa-angle-right {
  display: inline-block;
}
.searchBox .custom-dropdown .custom-dropdown-menu.spellCheck-search {
  top: -108px !important;
  background: transparent;
  box-shadow: unset;
  padding: 0;
  border-radius: 3px 3px 0px 0px;
}
.coures-spellCheck-Open {
  box-shadow: 0 0 2px 2px #ececec;
  background: #fff;
  padding: 6px 6px;
}
.searchBox
  .custom-dropdown-menu
  .accordBtn
  a[aria-expanded="true"]
  .fa-angle-right {
  display: none;
}
.searchBox
  .custom-dropdown-menu
  .accordBtn
  a[aria-expanded="false"]
  .fa-angle-down {
  display: none;
}
.searchBox .custom-dropdown-menu .accordBtn a:nth-of-type(1) {
  padding: 0 10px;
}
.searchBox .custom-dropdown-menu .collapse a {
  padding-left: 20px;
}
.searchBox .locationBtn img {
  margin-right: 3px;
  width: 18px;
}
.searchScroll {
  max-height: 130px;
  overflow-y: auto;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 5px;
}
.searchItemBtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.searchItemBtn a:nth-of-type(1) {
  color: #212529;
  padding: 0;
}
.searchItemBtn a:nth-of-type(1):hover,
.searchItemBtn a:hover,
.searchItemBtn a.active,
.qualificationDrop .searchItemBtn:hover {
  color: #f9b316;
}
.searchItemBtn a:nth-of-type(2),
.qualificationDrop .searchItemBtn a {
  color: #3f2566;
  background-color: #e9defa;
  padding: 0 10px;
}
.searchItemBtn a:nth-of-type(2):hover,
.qualificationDrop .searchItemBtn a:hover {
  color: #fff;
  background-color: #3f2566;
}
.searchTagWrap {
  top: -15px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.searchTag {
  background: #e9defa;
  font-size: 12px;
  margin: 0 5px;
  padding: 2px 15px 2px 5px;
  border-radius: 4px;
  color: #3f2566;
  position: relative;
}
.searchTag .unselectBtn {
  position: absolute;
  color: #3f2566;
  background-color: transparent;
  padding: 0;
  border: none;
  top: -3px;
  right: 5px;
  transform: none;
}
.searchTagWrap .searchTag {
  max-width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
}
.searchTagWrap .searchTag:nth-of-type(1) {
  margin-left: 0;
}
.searchTag:hover {
  cursor: pointer;
}
.spellCheck-list {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e6e5;
  /* margin-bottom: 10px; */
}

.spellCheck-list p {
  text-align: center;
  padding: 0px 0px 6px;
}
/* previous css for search */

/* .react-autosuggest__suggestions-container--open{top:33px!important;width:245px!important;}
.searchBox input{width:100%;padding:5px 10px;}
.searchBox input.form-control::placeholder,.searchBox input::placeholder,.searchBox .css-1wa3eu0-placeholder{color:#bdbcbc9e;text-align:center;}
.searchBox .css-1wa3eu0-placeholder{left:50%;transform:translate(-50%,-50%);}
.searchWrap .btn{padding:5px 18px;flex:1 1 auto;align-self:stretch;background:transparent;color:#fff;font-size:35px;}
.searchWrap .btn:nth-of-type(1){border-right:1px solid #4e4e4e;}
.searchWrap .btn img{width:30px;}
.searchWrap .btn span{display:none;}
.searchBox .css-yk16xz-control{background-color:transparent;border:none;border-bottom:1px solid #fff;border-radius:0;}
.css-g1d714-ValueContainer{outline:none;}
.css-yk16xz-control:hover,.css-1pahdxg-control:hover{border-color:#fff!important;}
.selectedSearch{display:none;}
.serchOption p{color:#1d1d1d;margin-bottom:0;}
.css-1pahdxg-control{background-color:transparent!important;}
.css-yk16xz-control:hover,.css-1pahdxg-control:hover{border-color:transparent!important;border-bottom-color:#fff!important;box-shadow:none!important;}
.css-b8ldur-Input input,.css-1g6gooi{color:rgb(94,86,86)!important;}
.css-26l3qy-menu p{color:#666;}
.css-1hb7zxy-IndicatorsContainer{display:none!important;}
.css-2b097c-container:focus,.css-yk16xz-control{outline:none!important;box-shadow:none!important;}
.css-b8ldur-Input input,.css-1g6gooi,.css-b8ldur-Input{color:#fff!important;}
.react-autosuggest__suggestions-list{max-height: 320px; overflow-y: scroll;} */

.gridMap {
  text-align: center;
}
.gridMap .btn {
  background-color: #f9b316;
  color: #fff;
  margin: 5px 10px;
  font-weight: 500;
  padding: 14px 25px;
}
.gridMap .btn img {
  width: 18px;
  margin-right: 10px;
}

/*=====menu=====================*/
.main-menu-div {
  text-align: left;
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
}
.main-menu-div ul {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.main-menu-div ul li {
  padding: 0;
  margin: 0;
  transition: all 0.2s linear;
}
.main-menu-div ul li a {
  line-height: normal;
  position: relative;
  z-index: 1;
}

/* only first level menu */
.main-menu-div > ul {
  margin: 0;
}
.main-menu-div > ul > li {
  display: inline-block;
  margin: 0 30px;
}
.main-menu-div > ul > ::before {
  display: none;
}
.main-menu-div > ul > li > a,
.main-menu-div > ul > li > button {
  display: inline-block;
}

/* submenu */
.main-menu-div ul li.has-sub {
  position: relative;
}
.main-menu-div ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: -42px;
  margin: 0;
  min-width: 400px;
  padding: 10px 20px;
  z-index: 9;
  border: 5px solid #fff;
  border-top: none;
}
.main-menu-div ul li.hovered > ul {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s linear;
  background-color: #021641;
}
.main-menu-div ul li ul li {
  display: block;
  height: 0;
  transition: all 0.3s linear;
}
.main-menu-nav ul li ul li::before {
  display: none;
}
.main-menu-div ul li.hovered > ul > li {
  height: 39px;
}
.main-menu-div ul li ul li a {
  color: #fff !important;
  line-height: 20px;
  display: block;
  padding: 10px;
  text-align: left;
}
.main-menu-div ul li ul li a:hover {
  background: #5878a5;
}
.main-menu-div > ul > li > ul > li > ul {
  top: 10px;
  left: 370px;
}
.main-menu-div > ul > li.hovered > ul > li.hovered > ul {
  top: 10px;
}
.main-menu-div > ul > li.hovered > ul > li.hovered > ul > li {
  height: 39px;
}
.main-menu-div > ul > li > ul:before {
  content: "";
  border-bottom: 10px solid #021641;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  top: -10px;
  position: absolute;
  left: 42px;
}

/* Changeable menu css */
.main-menu-div ul li a {
  padding: 15px;
  font-size: 16px;
  line-height: 1.2;
  text-transform: capitalize;
  font-weight: 500;
}
.main-menu-div > ul > li:hover,
.main-menu-div > ul > li > a.current-menu-item,
.main-menu-div > ul > li > button.current-menu-item,
.main-menu-div ul li ul {
  background: transparent;
}
.main-menu-div > ul > li > a,
.main-menu-div > ul > li > button {
  font-weight: 500;
  font-size: 15px;
  color: #3f2566;
  padding: 0;
  text-transform: capitalize;
  background-color: transparent;
  border: 0;
}

/*---------------universities-----*/
.mainFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}
.sidebar {
  width: 300px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #fff;
}
.mainContent {
  flex: 1 1 auto;
  padding-left: 30px;
}
.filterSidebar .top {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 10px;
  background: #f9b316;
  opacity: 1;
  z-index: 9;
}
.filterSidebar .top h5 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0;
}
.filterSidebar .top .btn {
  padding: 0;
  background: transparent;
  color: #fff;
}
.filterSidebar .top .btn:hover {
  color: #000;
}
.filterSidebar .filterItem {
  text-align: left;
}
.accordBtn {
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 0;
  border: none;
}
.accordBtn.collapsed .fa-angle-down {
  display: none;
}
.accordBtn.collapsed .fa-angle-right {
  display: inline-block;
}
.accordBtn .fa-angle-right {
  display: none;
}
.accordBtn .fa-angle-down {
  display: inline-block;
}

/* .mainFilter>.accordBtn{margin-bottom:10px;}
.mainFilter>.accordBtn .fa{font-size:23px;} */

/* .filterCheckbox{display:flex;justify-content:flex-start;align-items:flex-start;}
.filterCheckbox input[type="checkbox"]{flex-shrink:0;margin-right:5px;width:15px;height:15px;position:relative;margin-top:2px;}
.filterCheckbox input[type="checkbox"]::before{content:"";position:absolute;left:0;top:0;background:url('/src/static/images/unchecked.svg');background-repeat:no-repeat;background-position:center;width:15px;height:15px;}
.filterCheckbox input[type="checkbox"]:checked::before{background:url('/src/static/images/checked.svg');background-repeat:no-repeat;background-position:center;background-size:contain;}
.subAccordCollapse{padding-left:15px;} */
.filterBox {
  border-top: 1px solid #efefef;
  padding: 15px 0;
  box-shadow: -1px 9px 20px 2px #80808038;
}
.filterBox .filterSearchWrap {
  position: relative;
  margin: 0 15px 10px;
}
.filterBox .filterSearchWrap .form-control,
.filterCurrency .form-control {
  height: 35px;
  font-size: 13px;
  padding: 5px 10px 5px 30px;
}
.filterBox .filterSearchWrap .fa,
.filterCurrency img {
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translate(0, -50%);
}
.filterBox h6 {
  font-size: 15px;
  padding: 0 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterBox h6 .fa {
  font-size: 20px;
}
.filterItem {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  position: relative;
  font-size: 14px;
  color: #707070;
}
.filterItem span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterItem label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #707070;
}
/* .my-2{
	padding: 10px 0px;
} */
.px-15 {
  padding: 0 15px;
}
.filterItem span.active {
  color: #f9b316;
}
.filterItem > input {
  position: absolute;
  left: -20px;
  top: 3px;
}
.filterItem > input[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  background: url("/src/static/images/unchecked.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 13px;
  height: 13px;
}
.filterItem > input[type="checkbox"].childChecked::before {
  border-radius: 4px;
  background: url("/src/static/images/child-checked.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}
.filterItem > input[type="checkbox"]:checked::before,
.filterItem > input[type="checkbox"].childChecked:checked::before {
  border-radius: 4px;
  background: url("/src/static/images/checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.filterItem.single-select > input[type="checkbox"]:checked::before,
.filterItem.single-select
  > input[type="checkbox"].childChecked:checked::before {
  border-radius: 4x;
  background: url("/src/static/images/single-checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.filterItem.single-select > input[type="checkbox"] {
  accent-color: #fff;
}

.filterItemCont {
  padding-left: 20px;
  display: none;
}
.filterItemCont.show {
  display: block;
}
.filterBox h6 .fa-angle-right,
.filterItem .fa-angle-right {
  display: inline-flex;
}
.filterBox h6 .fa-angle-down,
.filterItem .fa-angle-down {
  display: none;
}
.filterBox h6.filterOpen .fa-angle-right,
.filterItem.filterOpen .fa-angle-right {
  display: none;
}
.filterBox h6.filterOpen .fa-angle-down,
.filterItem.filterOpen .fa-angle-down {
  display: inline-flex;
}
.filterModalBtn {
  font-size: 14px;
  color: #707070;
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
  text-align: left;
}
.filterModalBtn.active,
.filterModalBtn.active:hover {
  color: #f9b316;
}
.filterModalBtn .close {
  font-weight: 400;
  color: #000;
  opacity: 1;
  font-size: 18px;
  float: none;
  position: absolute;
  right: -15px;
  top: 43%;
  transform: translate(0, -50%);
}
.filterBoxScroll {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 15px;
}
.filterCurrency {
  position: relative;
}
.filterCurrency img {
  width: 18px;
}
.filterCurrency .form-control {
  background: #fff;
  border: 0;
  box-shadow: 0 0 5px 2px #eaeaea;
}
.rangeSlider {
  margin: 25px 20px 0;
}
.rangeSlider .MuiSlider-root {
  color: #3f2566;
}
.rangeSlider .MuiSlider-markLabelActive {
  color: #707070;
}
.rangeSlider .MuiSlider-markLabel {
  bottom: 26px;
  top: auto;
}
.filterSidebar Btn {
  margin: 0 auto;
  width: 70%;
  display: block;
}
.filterBox .content {
  display: none;
}
.filterBox .content.show {
  display: block;
}
.filterItem:hover,
.filterModalBtn:hover {
  color: #3f2566;
  cursor: pointer;
}
.rangeInputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.rangeInputs .inputWrap {
  width: 49%;
}
.rangeInputs .inputWrap .form-control {
  padding: 5px 10px;
  height: 35px;
}
.rangeInputs .inputWrap label {
  font-size: 14px;
}

/* .rangeSlider .MuiSlider-thumb{display: none;}  */

/* .subFilter{max-height:300px;overflow-y:auto;} */

/* .mainFilter>.accordBtn span{font-size:14px;}
.mainFilter .countrySearchWrap{width:100%;margin-bottom:10px;}
.mainFilter .countrySearchWrap .form-control{border:1px solid #ccc;padding:5px 10px;height:33px;}
.mainFilter .countrySearchWrap .btn{padding:5px 11px;height:33px;}
.mainFilter .countrySearchWrap .fa{position:relative;left:0;top:0;transform:none;color:#fff;font-size:inherit;} */
.locationAccord {
  background: transparent;
  border: none;
}

/* .filterCheckbox{font-size:14px;margin-bottom:10px;} */

/* .filterMoreBtn{background:transparent;border:none;padding:0;font-size:13px;text-decoration:underline;color:#666;margin-top:10px;} */
.mainContTop {
  margin-bottom: 20px;
}
.mainContTop h5 {
  margin-bottom: 0;
  font-weight: 500;
}
.mobTopbar {
  display: none;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #f9b316 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #f9b316 !important;
}
.sortFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
.campusFilter .css-2b097c-container {
  min-width: 170px;
}
.campusFilter .css-yk16xz-control {
  border: 1px solid #000 !important;
  background-color: white !important;
  height: auto;
}

/*.css-2b097c-container . css-2613qy-menu{*/

/*	width:1000px !important;*/

/*}*/
.campusFilter .css-1pahdxg-control {
  border: 1px solid #000 !important;
  background-color: white !important;
  height: auto; /*!* box-shadow: none!important;*/
}
.sortFilterTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.sortFilterTop > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sortFilterTop h5 {
  margin-right: 30px;
}
.sortFilter > div {
  display: flex;
  align-items: center;
}
.sortFilter p {
  margin: 0 5px;
}
.sortFilterTop .customCheckbox {
  color: #000;
  display: inline-flex;
}
.searchCourseDisp.searchBox,
.searchCourseDisp.searchBox .custom-dropdown {
  position: unset;
}
.searchCourseDisp.searchBox .custom-dropdown .custom-dropdown-menu {
  top: 70px !important;
}
/* .listToggle{display:flex;align-items:center;}
.listToggle p{font-size:13px;font-weight:500;} */
.sortFilter .primaryBtn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: #989898;
  border: 1px solid #989898;
}
.sortFilter .primaryBtn.active {
  background-color: #3f2566;
  border: 1px solid #3f2566;
  color: #fff;
}
.sortFilter .btn img {
  width: 15px;
  margin-right: 5px;
}
.sortFilter > p {
  font-weight: 500;
  margin: 0 10px 0 0;
}
.sortFilter .dropdown .btn {
  border: 1px solid #ccc;
  background-color: #fff;
  min-width: 138px;
  font-size: 13px;
}
.sortFilter .dropdown .dropdown-item {
  padding: 5px 5px;
  font-size: 13px;
}
.sortFilter .dropdown .dropdown-item:hover {
  cursor: pointer;
}
.sortFilter .dropdown .dropdown-item img {
  width: 20px;
}
.sortFilter .dropdown .dropdown-item img:nth-of-type(2) {
  width: 14px;
}
.sortFilter .dropdown .dropdown-menu {
  max-width: 100%;
  min-width: 100%;
}
.courseFlex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.courseBox {
  align-self: stretch;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 5px 2px #eaeaea;
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  width: 246px;
  position: relative;
}

.courseBox .imgWrap {
  position: relative;
  height: 160px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.courseBox .imgWrap > img {
  width: 100%;
  height: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.courseBox .imgWrap:before {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e9defa;
  content: "";
  z-index: 99;
}
.courseBox .imgWrap .btnGroup {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99;
}
.courseBox .imgWrap .btnGroup .btn {
  padding: 0;
  background: transparent;
}
.courseBox .imgWrap .btnGroup .btn img {
  width: 20px;
}
.courseBoxTop {
  width: 100%;
  z-index: 99;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 10px 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.courseBoxTop a {
  display: inline-block;
}
.courseBoxTop > a {
  margin-bottom: 5px;
}
.courseBoxTop > a img {
  width: 100px;
  height: 100px;
}
.courseBoxTop > img {
  width: 40px;
  flex-shrink: 0;
}
.courseBoxTop > div {
  padding-left: 0px;
  text-align: center;
}
.courseBoxTop h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.courseBoxTop h6 {
  color: #3f2566;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0; /*display:-webkit-box;-webkit-line-clamp:1;overflow:hidden;text-overflow:ellipsis;-webkit-box-orient:vertical;*/
}
.courseBoxTop p:nth-of-type(1) {
  color: #3f2566;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.courseBoxTop p:nth-of-type(1) span {
  text-transform: capitalize;
}
.courseBoxTop p:nth-of-type(2) {
  color: #fff;
  font-size: 11px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 0;
}
.courseBoxCont > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courseBoxCont > div:nth-of-type(1) div:nth-of-type(1) {
  width: 60%;
}
.courseBoxCont > div:nth-of-type(1) div:nth-of-type(2) {
  width: 40%;
  padding-left: 10px;
}
.courseBoxCont {
  padding: 10px;
  border-top: 3px solid #3f2566;
  padding-bottom: 70px;
  align-self: stretch;
}
.courseBoxCont h6 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
.courseBoxCont p {
  font-size: 11px;
  margin-bottom: 4px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.courseBoxCont h6 + p {
  font-weight: 600;
  position: relative;
  padding-right: 15px;
}
.courseBoxCont h6 + p a {
  position: absolute;
  right: 0;
  top: 3px;
}
.courseBoxCont h6 + p a img {
  width: 13px;
  margin: 0;
}
.courseBoxCont p img {
  width: 24px;
  margin-right: 3px;
  display: inline-block;
}
.courseBoxCont p .fa {
  color: #3f2566;
  margin-right: 5px;
  font-size: 15px;
}
.courseBoxCont .btnGroup .btn {
  background: #f9b316;
  color: #fff;
  width: 49%;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  margin: 0 5px;
}

/* .courseBoxCont .btnGroup .btn:nth-last-of-type(2){background:#3F2566;}
.courseBoxCont .btnGroup .btn:nth-last-of-type(1){background-color:#F9B316;} */
.courseBoxCont p.relevantP {
  color: #ff000a;
  text-align: center;
  margin-bottom: 0px;
}
.courseBoxCont p.relevantP a {
  color: inherit;
}
.courseBoxCont .btnGroup {
  margin: 10px 0;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
}
.courseBoxFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
}

/* .courseBoxFoo,.uniBoxFoo{padding:10px;border-top:1px solid #efefef;display:flex;justify-content:space-between;align-items:center;}
.courseBoxFoo .btn,.uniBoxFoo .btn{margin:0 5px;padding:0;border:none;background:transparent;font-size:11px;display:inline-flex;justify-content:flex-start;align-items:center;}
.courseBoxFoo>div:nth-of-type(1) .fa{margin-right:5px;}
.courseBoxFoo .fa{font-size:17px;}
.courseBoxFoo>div:nth-of-type(1) .btn:nth-of-type(1){color:#3F2566;margin-left:0;}
.courseBoxFoo>div:nth-of-type(2) .btn:nth-of-type(1){color:#3F2566;}
.courseBoxFoo>div:nth-of-type(1) .btn:nth-of-type(2){color:#C22127;}
.courseBoxFoo>div:nth-of-type(2) .btn:nth-of-type(2){color:#F9B316;}
.uniBoxFoo .btn{color:#3F2566;}
.uniBoxFoo .btn img{width:15px;}
.uniBoxFoo .btn:nth-last-child(1){color:#C22127;} */
.courseBox .imgWrapADD {
  position: relative;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.imgWrapADD .cimg1 {
  height: 100%;
  width: 100%;
}
.courseBox:hover,
.universityList:hover {
  box-shadow: 0 0 5px 5px #30195430;
}
.courseBox.adBox .imgWrap:before {
  display: none;
}
.courseBox.adBox .courseBoxCont p {
  line-height: 22px;
}
.courseBox.adBox .courseBoxCont .btnGroup .btn {
  width: 90%;
  margin: 0 auto;
}
.adBoxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.adBoxOverlay .imgWrap {
  width: 100%;
  height: 100%;
}
.adBoxOverlay:before {
  background: #2c1d666b;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 99;
  display: block;
}
.adBoxOverlay .overlayCont {
  position: absolute; /*top:50%;*/
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 99;
  bottom: 0;
}
.overlayCont.courseBoxCont h5,
.overlayCont.courseBoxCont h6,
.overlayCont.courseBoxCont .btn,
.overlayCont.courseBoxCont p {
  color: #fff;
}
.adBox:hover .adBoxOverlay {
  opacity: 1;
}
.adBox:hover .courseBoxCont {
  border-top: none;
}
.relevantBtn {
  color: #f90009;
  padding: 0;
  border: none;
  font-size: 13px;
  display: block;
  margin-left: auto;
}
.word-confirm {
  text-align: left;
  font-size: 14px;
  border: 1px solid #d8d8d8;
  display: inline-block;
  padding: 2px 15px;
  background: transparent;
  border-radius: 5px;
}
/*course detail in course camparison*/
.detailBtnMain {
  padding: 14px 30px;
}
.detailBtnMain .detailBtnRow {
  background: rgb(233, 222, 250) !important;
}
.detailBtnRow td:nth-of-type(1) {
  padding-left: 30px;
  padding-top: 17px;
}
.detailBtnRow td {
  border-left: 0 !important;
  padding: 10px;
}
.detailBtnRow td h6 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
  color: rgb(63, 37, 102);
}
.detailBtnRow td a,
.detailBtnRow td button {
  border: 0;
  background: none;
  background-color: #f9b316;
  display: inline-block;
  text-decoration: none !important;
  padding: 8px 22px;
  border-radius: 6px;
  color: white !important;
}
.detailBtnRow td a:hover,
.detailBtnRow td button:hover {
  background-color: #f9b316a8;
}

/*---------------university list view-------------*/
.universityList {
  max-height: 160px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0 0 5px 2px #eaeaea;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 12px;
  position: relative;
}
.universityList .imgWrap {
  /* background-color:#e9defa; */
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:20px; */
  align-self: stretch;
  flex-shrink: 0;
}
.universityList .imgWrap button {
  border: none;
}
.universityList .imgWrap img {
  width: 150px;
}
.universityList h4 {
  color: #3f2566;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
}
.universityList h5 {
  color: #3f2566;
  font-size: 11px;
  line-height: 17px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-weight: 400;
}
.universityList h6 {
  padding-right: 15px;
  position: relative;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.universityList h6 a {
  position: absolute;
  right: -4px;
  top: 0px;
}
.universityList h6 a img {
  width: 13px;
  margin: 0;
}
.universityList .course-title {
  font-size: 18px;
  padding-right: 20px;
  margin-bottom: 0px;
}
.universityList ul {
  padding: 0;
  margin: 0 0 20px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.universityList ul li {
  padding: 0 5px 0 0;
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 18px;
  width: 25%; /*display:-webkit-box;-webkit-line-clamp:1;overflow:hidden;text-overflow:ellipsis;-webkit-box-orient:vertical; */
}
.universityList ul li:nth-of-type(1),
.universityList ul li:nth-of-type(2),
.universityList ul li:nth-of-type(4),
.universityList ul li:nth-of-type(5) {
  width: 37.5%;
}
.universityList ul li img {
  width: 24px;
  margin-right: 3px;
  display: inline-block;
}
.universityList .contentWrap {
  padding: 12px;
  flex: 1 1 auto;
}
.uniListFoo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uniListFoo p {
  color: #ff000a;
  margin-bottom: 0;
  font-size: 12px;
}
.uniListFoo p a {
  color: inherit;
}
.uniListFoo .btnGroup .btn {
  background: #f9b316;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  margin: 0 5px;
  width: 90px;
}
.topBtnGroup {
  position: absolute;
  right: 5px;
  top: 5px;
  display: grid;
}
.topBtnGroup .btn {
  padding: 0;
  background: transparent;
}
.topBtnGroup img {
  width: 18px;
}
.topBtnGroup .share {
  transform: rotate(180deg);
}

/*---------------edit review modal-----------*/
.editModal .modal-dialog {
  max-width: 850px;
}
.editModal h6 {
  font-size: 17px;
  text-decoration: underline;
  margin-bottom: 20px;
}
.editModal.signinModal ul {
  padding: 0;
  margin: 0 0 20px;
  list-style-type: none;
}
.editModal.signinModal ul li {
  display: block;
  padding-left: 15px;
  position: relative;
  margin: 0 0 5px;
  text-align: left;
  font-size: 14px;
  color: #031029cc;
}
.editModal.signinModal ul li:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #f9b316;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.editModal .btnGroup {
  text-align: center;
}
.editModal .btnGroup .btn {
  width: 170px;
  margin: 0 10px;
}

/*---------------institute card-----------*/
.courseBox.instituteBox .courseBoxCont {
  padding-bottom: 10px;
}
.courseBox.instituteBox .courseBoxCont .btnGroup {
  justify-content: center;
}

/* .courseBox.instituteBox .courseBoxTop{flex-direction:row;align-items:flex-start;}
.courseBox.instituteBox .courseBoxTop>div{text-align:left;padding-left:10px;} */
.courseBox.instituteBox .courseBoxCont > div > div {
  width: 50%;
}
.courseBox.instituteBox .courseBoxCont > div > div:nth-of-type(2) {
  text-align: right;
}
.courseBox.instituteBox .courseBoxCont p {
  font-weight: 500;
}
p.views img {
  width: 20px;
}
p.views {
  font-size: 12px;
  margin-bottom: 0;
  color: #3f2566;
  font-weight: 500;
}

/*---------------university program-----------*/
.uniProgTitle {
  font-weight: 500;
  max-width: 700px;
  text-align: center;
  margin: 0 auto 50px;
  font-size: 32px;
  line-height: 39px;
}
.uniSliderWrap {
  background-color: #fff;
  padding: 50px 0;
}
.uniSliderWrap h5 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.uniSliderWrap .slick-prev,
.uniSliderWrap .slick-next {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 4px 2px #d8d7d7;
}
.uniSliderWrap .slick-prev:before,
.uniSliderWrap .slick-next:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "fontawesome";
  font-size: 15px;
  color: #f9b316;
}
.uniSliderWrap .slick-prev:before {
  content: "\f053";
}
.uniSliderWrap .slick-next:before {
  content: "\f054";
}
.uniSliderWrap .slick-prev {
  left: -30px;
}
.uniSliderWrap .slick-next {
  right: -30px;
}
.uniSliderWrap .container > .btn {
  width: max-content;
  background-color: #f9b316;
  color: #fff;
  font-weight: 500;
  padding: 7px 20px;
  display: block;
  margin: 30px auto 0;
}
.uniSliderWrap .scholarBox {
  margin: 10px auto;
}
.uniSliderWrap .scolarshipCard {
  margin: 0 auto;
}

/*---------------footer-----*/
.footer {
  background: #e9defa;
  border-top: 6px solid #3b3282;
}
.fooTopFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.fooTopFlex .left {
  order: 2;
  flex-shrink: 0;
}
.fooTopFlex .right {
  margin-right: 100px;
  flex: 1 1 auto;
}
.fooTopFlex .left ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.fooTopFlex .left ul li {
  margin-bottom: 5px;
}
.fooTopFlex .left ul li a,
.fooTopFlex .left ul li button {
  font-size: 14px;
  color: #000;
}
.fooTopFlex .left ul li a:hover,
.fooTopFlex .left ul li button:hover {
  text-decoration: underline;
}
.fooTopFlex {
  padding: 30px 0;
}
.fooLogo img {
  width: 180px;
}
.fooLogo {
  margin-bottom: 20px;
  display: inline-block;
}
.fooLogo span {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  margin-left: 10px;
}
.fooTopFlex .right h6 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.fooTopFlex .right ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 20px;
}
.fooTopFlex .right ul li {
  display: inline-block;
  padding: 0 20px 0 0;
  position: relative;
  line-height: 1;
}
.fooTopFlex .right ul li button,
.fooTopFlex .left ul li button {
  background-color: transparent;
  border: 0;
}
.fooTopFlex .right ul li a,
.fooTopFlex .right ul li button {
  color: #000;
  font-size: 13px;
  font-weight: 300;
}
.fooTopFlex .right ul li a:hover,
.fooTopFlex .right ul li button:hover {
  text-decoration: underline;
}
.fooTopFlex .right ul li:nth-of-type(1) {
  padding-left: 0;
}
.fooTopFlex .right ul li:after {
  background: #000;
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.fooTopFlex .right ul li:nth-last-of-type(1):after {
  display: none;
}

/* .footer h6{color:#F9B316;font-size:17px;font-weight:500;margin-bottom:20px;} */

/* .contactBox p{margin-bottom:5px;}
.contactBox p:nth-of-type(2){margin-bottom:20px;}
.contactBox a{color:#fff;font-size:14px;}
.contactBox a .fa{font-size:17px;margin-right:5px;}
.fooBox ul{padding:0;margin:0;list-style-type:none;}
.fooBox ul li{margin-bottom:5px;}
.fooBox ul li a{font-size:13px;color:#fff;}
.fooBox ul li a:hover,.contactBox a:hover{text-decoration:underline;}
.footer p.copyright{color:#fff;text-align:center;padding:10px 0;font-size:14px;margin-bottom:0;border-top:1px solid #777;} */

/*---------------landing page----------*/
.homePage {
  background-color: #fff;
}
.homePage .courseBanner {
  height: 440px;
}
.homePage .courseBanner > img {
  height: 100%;
}

/* location section */
iframe.map {
  max-width: 100%;
  height: auto;
  width: 100%;
  min-height: 400px;
}
.locationSec {
  text-align: center;
  margin-bottom: 50px;
}
.locationSec .locationDropdown {
  max-width: 500px;
  margin: 0 auto 20px;
}
.locationSec .countrySearchWrap input.form-control {
  background: #e9defa;
  border-radius: 4px;
}
.locationSec .locationDropdown .btnWrap .fa {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.locationSec .locationDropdown .dropdown-menu {
  max-width: 100%;
}
.locationSec .locationDropdown .dropdown-item {
  padding: 3px 10px;
  font-size: 14px;
  white-space: break-spaces;
}
.locationSec .locationDropdown .dropdown-item:focus {
  background: #e9defa78;
}
.mapCard .logo {
  display: inline-block;
  margin-bottom: 15px;
}
.mapCard h6 {
  margin-bottom: 15px;
  font-weight: 500;
  max-width: 400px;
  font-size: 16px;
  line-height: 23px;
}
.locationSec .mapCard .btn {
  margin-bottom: 0;
  font-weight: 400;
}

/* scholarship section */
.scholarshipTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -10px 30px;
}
.scholarshipTop .scolarshipCard.flip-card {
  width: 49% !important;
  height: 323px !important;
  border-radius: 7px;
}
.scolarshipCard .flip-card-front {
  height: 100%;
  border-radius: 7px;
}
.scolarshipCard .flip-card-front img {
  border-radius: 7px;
  height: 100%;
  object-fit: cover;
}
.scolarshipCard .flip-card-back {
  border-radius: 7px;
  background-color: #3f256694;
  height: 100%;
}
.scolarshipCard .flip-card-front:before {
  border-radius: 7px;
  background-color: #3f25664f;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
}
.scolarshipCard .flip-card-front h2 {
  width: 100%;
  padding: 20px;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 24px;
}
.scholarshipTop .scolarshipCard .flip-card-front h2 {
  font-size: 25px;
  text-decoration: underline;
}
.scolarshipCard .flip-card-back .content {
  position: absolute;
  width: 100%;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.scolarshipCard .flip-card-back h3 {
  color: #fff;
}
.scolarshipCard .flip-card-back h4 {
  color: #fff;
  margin-bottom: 20px;
}
.scolarshipCard.flip-card:hover .flip-card-front h2 {
  display: none;
}
.scolarshipCard.flip-card:hover .flip-card-front:before {
  background-color: transparent;
}
.scholarshipBottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -10px 10px;
}
.scolarshipCard {
  margin: 0 10px;
  width: 222px !important;
  height: 131px !important;
  border-radius: 7px;
}
.scholarshipSec h2,
.locationSec h5,
.studyAbroadSec h5 {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}
.scholarshipSec p {
  margin-bottom: 50px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.locationSec p {
  margin-bottom: 20px;
  font-size: 15px;
  text-align: center;
}
.locationSec .btn {
  background-color: #f9b316;
  color: #fff;
  margin-bottom: 30px;
  padding: 5px 15px;
  font-weight: 500;
}
.seeAllContBtn {
  background-color: transparent !important;
  padding: 0 !important;
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: center;
  color: #3f2566 !important;
  font-weight: 600 !important;
}
.seeAllContBtn .fa {
  margin-left: 5px;
  font-size: 20px;
}
.scholarshipSec {
  margin-bottom: 50px;
}
.uniSliderWrap .seeAllContBtn {
  background-color: transparent;
  padding: 0;
  color: #3f2566;
  font-weight: 600;
  display: flex !important;
  margin: 30px auto 0 !important;
  width: max-content;
  align-items: center;
}
.uniSliderWrap .courseBox {
  margin: 10px auto;
}

/* future leader section */
.futureLeadSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}
.futureLeadSec .left {
  width: 35%;
}
.futureLeadSec .left h3 {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 32px;
}
.futureLeadSec .left p {
  text-transform: uppercase;
}
.futureLeadSec .left img {
  border-radius: 10px;
}
.logoFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.futureLeadSec .right {
  width: 44%;
}
.logoBox {
  border: none;
  box-shadow: 0 0 5px 2px #f3f1f1;
  border-radius: 5px;
}
.futureLeadSec .logoBox {
  width: 48%;
  margin-bottom: 15px;
}
.logoBox .flip-card-front {
  background: #fff;
}
.logoBox .flip-card-front img {
  max-width: 135px;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.logoBox .flip-card-back img {
  max-width: 65px;
}
.logoBox:hover .flip-card-front img {
  display: none;
}
.logoBox:hover .flip-card-back .content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
.logoBox:hover .flip-card-back .content img {
  width: 80%;
  margin: 0 auto 10px;
}
.logoBox:hover .flip-card-back .content p {
  color: #fff;
}
.futureLeadSec .logoFlex + .btn {
  width: max-content;
  color: #3f2566;
  border: 1px solid #3f2566;
  padding: 10px 30px;
  margin: 0 auto;
  display: block;
  line-height: 1;
  font-weight: 500;
}
.futureLeadSec .logoFlex + .btn:hover {
  background-color: #3f2566;
  color: #fff;
}

/* top ranked section */
.topRankedSec {
  background-color: #3f2566;
  padding: 50px 0;
}
.topRankedSec h5 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  max-width: 390px;
  margin: 0 auto 5px;
  line-height: 32px;
  font-size: 24px;
}
.topRankedSec p {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 45px;
}
.topRankedSec .logoBox {
  width: 255px;
  margin-bottom: 30px;
  box-shadow: none;
}
.topRankedSec .btn {
  width: max-content;
  display: block;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 60px;
  display: block;
  line-height: 1;
  font-weight: 600;
  background: transparent;
}
.topRankedSec .logoBox .flip-card-front img {
}
.topRankedLogoFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topRankedLogoFlex > div {
  width: 48.67%;
}
.topRankedLogoFlex .topRankBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-bottom: 30px; /*background:url('/src/static/images/top-ranked-btn.svg');background-position:center;background-repeat:no-repeat;background-size:inherit;*/
  border-radius: 5px;
  background-color: #f9b316;
}

/* .topRankedLogoFlex .logoBox{width: 50%;} */

/* study abroad section */
.studyAbroadSec {
  padding: 50px 0;
}
.studyAbroadFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.studyAbroadFlex:before {
  content: "";
  position: absolute;
  top: 70px;
  left: 50%;
  height: 2px;
  width: 915px;
  border: 2px dashed #ccc;
  transform: translate(-50%, 0);
}

/* .studyAbroadSec h5{margin-bottom:20px;font-weight:500;} */
.studyAbroadSec h3 {
  margin-bottom: 0px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.studyAbroadSec p {
  text-align: center;
}
.studyAbroadBox h6 {
  line-height: 23px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.studyAbroadBox > div {
  position: relative;
  height: 140px;
}
.studyAbroadBox > div img {
  width: 42%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.studyAbroadBox {
  width: 14%;
}

/* counsel section */
.counselSec {
  background-color: #f9b316;
  padding: 50px 0;
}
.counselSec h5 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 30px;
}
.counselFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.counselBox h6 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.counselBox {
  width: 20%;
  text-align: center;
}
.counselBox img {
  margin-bottom: 15px;
  width: 100px;
}
.counselSec .btn {
  padding: 8px 35px;
  background-color: #fff;
  margin: 0 auto;
  display: block;
  font-weight: 600;
  font-size: 16px;
}

/* leading world section */
.leadingWorldSec {
  background-color: #f9b316;
  padding: 50px 0;
}
.leadingWorldSec h3 {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}
.leadingWorldSec h4 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
  line-height: 40px;
}
.leadBox {
  border: 0;
  transition: width 0.3s ease-in-out;
  width: 200px;
  display: block;
  margin: 25px auto;
  height: 200px;
  background: #fff;
  border-radius: 5px;
  position: relative;
}
.leadBox img {
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.leadingWorldSec .slick-prev,
.leadingWorldSec .slick-next {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
}
.leadingWorldSec .slick-prev:before,
.leadingWorldSec .slick-next:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "fontawesome";
  font-size: 15px;
  color: #f9b316;
}
.leadingWorldSec .slick-prev:before {
  content: "\f053";
}
.leadingWorldSec .slick-next:before {
  content: "\f054";
}
.leadingWorldSec .slick-prev {
  left: -30px;
}
.leadingWorldSec .slick-next {
  right: -30px;
}
.leadingWorldSec .slick-current .leadBox {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
.leadingWorldSec .viewAllBtn {
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 25px;
  display: block;
  margin: 30px auto 0;
  width: max-content;
}

/*---------------course detail page----------*/
.courseDetailBanner {
  position: relative;
  margin-bottom: 50px;
  height: 235px;
}
.courseDetailBanner > img {
  width: 100%;
  height: 100%;
}
.courseDetailBanner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2c1d6633;
}
.courseDetailBanner .content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.courseDetailBanner h1 {
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
  line-height: 25px;
}
.courseDetailBanner p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.courseDetailBanner a {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  flex-shrink: 0;
}
.courseDetailBanner a img {
  width: 180px;
}
.courseDetailBanner .btn {
  background-color: #f9b316;
  color: #fff;
}
.tableWrap > h6 {
  background-color: #3f2566;
  padding: 14px 30px;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
}
.tableWrap .table {
  background-color: #fff;
  margin-bottom: 0;
}
.tableWrap .table td,
.tableWrap .table th {
  border-top: none;
}
.tableWrap .table td {
  font-size: 14px;
}
.tableWrap {
  margin-bottom: 30px;
  box-shadow: 0 0 5px 5px #eae9e9;
}
.tableWrap .table td a {
  color: #c22127;
  text-decoration: underline;
}
.tableWrap thead tr {
  background-color: #3f2566;
}
.tableWrap thead tr th {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
.tableWrap .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f7f8f9;
}
.courseDetailPage .tableWrap .table td:nth-of-type(1) {
  padding-left: 30px;
}
.cdSidebar {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 5px;
}
.cdSidebar h5 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.cdSidebar .sendBtn {
  background-color: #f9b316;
  color: #fff;
  font-weight: 500;
  width: 70%;
  margin: 0 auto;
  display: block;
  padding: 7px 10px;
}
.shortlistBox {
  margin-bottom: 10px;
  position: relative;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}
.shortlistBox .btn {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  background: transparent;
  border: none;
}
.shortlistBox h6 {
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 500;
  color: #3f2566;
}
.shortlistBox p {
  margin-bottom: 0;
}
.cdSidebar > p {
  text-align: center;
  font-size: 13px;
}
.cdSidebar label {
  font-size: 13px;
  font-weight: 500;
  color: #3f2566;
}
.cdSidebar label.customCheckbox {
  color: #707070;
  margin-bottom: 30px;
}

/* .cdSidebar .form-control{background-color:#F5F5F5;border:none;height:40px;}
.cdSidebar textarea.form-control{height:100px;} */
.cdSidebar .checkboxLabel a {
  color: #3f2566;
}
.helpSection {
  height: 280px;
  position: relative;
  background: url("/src/static/images/help-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.helpSection:before {
  content: "";
  background-color: #0808087a;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.helpSection h2 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
}
.helpSection .btn {
  background: #fff;
  font-weight: 600;
  padding: 8px 30px;
}
.helpSection .content {
  width: 100%;
  padding: 20px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.appProcessSec {
  padding: 80px 0;
  background: url("/src/static/images/app-process-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.appProcessSec h5 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 100px;
}
.appProcessFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.appProcessBox {
  box-shadow: 0 0 5px 5px #f3f3f3;
  border-left: 3px solid #f9b316;
  background-color: #fff;
  width: 18%;
  padding: 30px;
  text-align: center;
}
.appProcessBox img {
  width: 65px;
  margin-bottom: 20px;
}
.appProcessBox h6 {
  font-weight: 500;
  font-size: 15px;
}
.counselWorkSec {
  padding: 50px 0;
  background-color: #f5f5f5;
}
.counselWorkSec .container h5 {
  text-align: center;
  font-weight: 600;
}
.counselWorkSec .container p {
  text-align: center;
  margin-bottom: 50px;
}
.counselWorkSec .counselWorkBox p {
  margin-bottom: 15px;
}
.counselWorkFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.counselWorkBox {
  width: 12%;
  text-align: center;
  position: relative;
}
.counselWorkBox:before {
  background-color: #ababab;
  content: "";
  position: absolute;
  top: 71px;
  right: -69px;
  width: 70px;
  height: 3px;
}
.counselWorkBox img {
  margin-bottom: 20px;
}
.counselWorkBox h6 {
  font-size: 15px;
  padding-bottom: 15px;
  line-height: 22px;
  position: relative;
}
.counselWorkBox h6:before {
  content: "";
  width: 50px;
  height: 3px;
  background-color: #f9b316;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.counselWorkBox p {
  font-size: 13px;
}
.counselWorkBox:nth-last-of-type(1):before {
  display: none;
}
/* .MuiOutlinedInput-notchedOutline{border:none!important;} */

/* .MuiFormControl-marginNormal{background-color:#F5F5F5!important;border-radius:4px!important;} */
/* .MuiInputLabel-outlined.MuiInputLabel-shrink{display:none!important;} */
/*.MuiOutlinedInput-input{padding:13.5px 14px 10.5px 0!important;}*/
/* .MuiOutlinedInput-input{padding:7.5px 14px 10.5px 0!important;} */
.MuiFormControl-marginNormal {
  margin: 0 !important;
}

/* .courseDetailPage .css-1hvq1u5-control{border:none!important;background:#f5f5f5!important;}
.courseDetailPage .css-1wa3eu0-placeholder{display:none!important;} */

/*---------------discipline page----------*/
.disciplineBanner {
  position: relative;
  height: 380px;
}
.disciplineBanner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2c1d6640;
}
.disciplineBanner > img {
  width: 100%;
  height: 100%;
}
.disciplineFlex {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  padding: 15px;
}
.disciplineFlex .left {
  width: 50%;
}
.disciplineFlex .left a {
  display: inline-flex;
  margin-bottom: 0;
}
.disciplineFlex .left a img {
  width: 250px;
}
.disciplineFlex .left h1 {
  color: #fff;
  font-weight: 600;
  font-size: 28px;
}
.disciplineFlex .left p {
  color: #fff;
  font-size: 23px;
  line-height: 28px;
}
.disciplineFlex .right {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.disciplineFlex .right .whiteWrap {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}
.disciplineFlex .right h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.factFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.factBox {
  width: 48%;
  background-color: #f7f8f9;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.factBox div {
  padding-left: 15px;
}
.factBox label {
  color: #707070;
}
.factBox p {
  margin-bottom: 0;
  color: #3f2566;
  font-weight: 600;
}
.disciplineInfoBanner {
  margin-bottom: 50px;
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
}
.disciplineInfoBanner h1 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}
.disciplineInfoBanner h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.disciplineInfoBanner p {
  text-align: justify; /*display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:6;overflow:hidden;text-overflow:ellipsis;*/
}
.disciplineInfoBanner .btn {
  background-color: #3f2566;
  color: #fff;
  padding: 7px 30px;
  font-weight: 500;
}
.disciplineFlex .left .uniBannerBtnGroup p {
  font-size: 16px;
  max-width: 450px;
  line-height: 20px;
}
.uniBannerBtnGroup .btn {
  background-color: #fff;
  color: #000;
  padding: 7px 21px;
}
.uniBannerBtnGroup .btn:nth-of-type(1) {
  background-color: #4fb7dd;
  color: #fff;
  margin-right: 15px;
}
.uniBannerBtnGroup .btn img {
  width: 15px;
  margin-right: 5px;
}
.disInfoFlex {
  max-width: 900px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.disInfoFlex .box {
  background: #f7f8f9;
  border: 1px solid #e9defa;
  padding: 15px;
  width: 28%;
}
.disInfoFlex .box .btn {
  display: block;
  width: 100%;
  background-color: #f9b316;
  padding: 5px 10px;
}
.disInfoFlex .box img {
  margin-bottom: 15px;
  width: 40px;
  height: 40px;
}
.discoverTagFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.discoverTags h5 {
  margin-bottom: 5px;
}
.discoverTags h6 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
}
.discoverTags h4 {
  color: #f9b316;
  text-transform: uppercase;
  font-size: 17px;
  margin: 50px 0 30px;
}
.discoverTagFlex p {
  border: 1px solid #707070;
  color: #707070;
  background: #f5f5f5;
  padding: 2px 10px;
  border-radius: 15px;
  margin: 5px;
}
.discoverTagFlex.purple p {
  color: #3f2566;
  border-color: #3f2566;
  background: #e9defa;
}

/*-------------------university individul page---------------*/
.twelveFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.twelveBox {
  /*height:250px;*/
  padding-bottom: 15px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: visible;
  box-shadow: 0 0 5px 5px #eaeaea;
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  width: 262px;
  position: relative;
}
.twelveBox .imgWrap {
  position: relative;
  height: 160px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.twelveBox .imgWrap > img {
  width: 100%;
  height: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.twelveBox .titleBox {
  position: absolute;
  background: #f9b316c7;
  width: 100%;
  bottom: 0;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.twelveBox .titleBox img {
  margin-right: 5px;
  width: 18px;
}
.twelveBox .titleBox h6 {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.twelveBox > .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}
.twelveBox .dropdown .btn:hover {
  background-color: #e8e7e7;
}
.twelveBox .dropdown .dropdown-menu {
  border: none;
  box-shadow: 0 0 3px 3px #e8e8e8;
  left: auto !important;
  right: 10px;
}
.twelveBox .dropdown button.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0;
}
.twelveBox .dropdown .dropdown-item {
  font-size: 14px;
  padding: 5px 15px;
}
.twelveBox .dropdown .dropdown-item:hover {
  background-color: #3f2566;
  color: #fff;
}
.uniProgramPage .countryBanner {
  margin-bottom: 30px;
}

/*-----------------newsletter--------------*/
.newsletter {
  background-color: #f9b316;
  padding: 30px 0;
}
.newsletter h3 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.newsletter p {
  text-align: center;
  color: #fff;
}
.newsletter p img {
  width: 8px;
  margin: 0 5px;
}
.newsForm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.newsForm .form-control {
  border-radius: 0;
  border: none;
}
.newsletter .btn {
  padding: 7px 50px;
  display: block;
  width: max-content;
  margin: 0 auto;
}

/*----------------------country page-------------------*/
.countryBanner {
  position: relative;
}
.countryBanner > img {
  width: 100%;
}
.countryBanner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2c1d666b;
}
.countryBanner .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  min-width: 600px;
}
.countryBanner h1,
.countryBanner h3 {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #fff;
}
.countryBanner h2 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
}
.countryBanner .logo {
  width: 110px;
}
.countrySearchWrap {
  max-width: 545px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.countrySearchWrap .fa {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  color: #666;
  font-size: 18px;
}
.countrySearchWrap .form-control {
  background-color: #fff;
  border: none;
  height: 45px;
  padding-left: 35px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.countrySearchWrap .btn {
  background: #f9b316;
  color: #fff;
  height: 45px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 25px;
  font-weight: 500;
}
.countryInfoFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.countryInfoFlex .left {
  text-align: left;
  flex: 1 1 auto;
  padding-right: 50px;
}
.countryInfoFlex .right {
  flex-wrap: wrap;
  width: 285px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}

/* .countryInfoFlex .right img{width:128px;margin:15px 0;} */

/* .countryInfoFlex .right::before{content:"";position:absolute;width:70px;height:70px;border-radius:50%;background-color:#fff;top:50%;left:50%;transform:translate(-50%,-50%);} */
.uniRankSec {
  background-color: #fff;
  padding: 50px 0;
}
.uniRankSec table tr {
  text-align: center;
}
.uniRankSec table tr:hover {
  cursor: pointer;
}
.paginationWrap {
  text-align: right;
  max-width: 100%;
}
.paginationWrap ul li.disabled a {
  opacity: 0.5;
  display: none;
}
.paginationWrap ul {
  display: inline-flex;
}
.paginationWrap ul li {
  margin: 0 5px;
}
.paginationWrap ul li a,
.paginationWrap ul li .page-link {
  font-size: 14px;
  background: #fff;
  border: none;
  box-shadow: 0 0 3px 3px #f5f5f5;
  color: #000;
  white-space: nowrap;
}
.paginationWrap ul li a:hover,
.paginationWrap ul li a:focus {
  box-shadow: 0 0 3px 3px #f5f5f5;
  color: #f9b316;
}
.paginationWrap ul li a.active {
  background-color: #f9b316;
  color: #fff;
}
.uniRankSec .table tr td:nth-of-type(1) a {
  color: #000;
  text-decoration: none;
}
.MUIDataTableHeadCell-data-50 {
  color: #000;
  font-weight: 600;
}
.uniRankSec .tableWrap thead tr th {
  min-width: 200px;
}
.uniRankSec .tableWrap tbody tr td:nth-of-type(1) {
  text-align: left;
}
.uniRankSec .tableWrap tbody tr td {
  text-align: center;
}
.countryFaqs {
  margin-top: 50px;
}
.countryFaqs h6 {
  color: #000;
  text-align: left;
  margin-bottom: 25px;
  font-size: 18px;
}
.countryFaqs .accordion > .card {
  background: white;
  border: none;
  margin-bottom: 10px;
}
.countryFaqs .accordion .btn {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 10px 20px;
  font-weight: 600;
  text-align: left;
}
.countryFaqs .accordion .btn .fa {
  color: #9a9a9a;
}
.countryFaqs .accordText {
  padding: 0 20px 10px;
}
.countryFaqs .accordText p:nth-last-of-type(1) {
  margin-bottom: 0;
}

/*---------------------all countries page----------------*/
.allCountriesBanner {
  position: relative;
  margin-bottom: 50px;
}
.allCountriesBanner > img {
  width: 100%;
}
.allCountriesBanner .content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.allCountriesBanner h1 {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 0px;
  color: #fff;
}

/* .allConSec h5{text-align:center;font-weight:400;margin-bottom:30px;} */
.allConWrap {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 0 5px 5px #ece9e9;
  margin-bottom: 50px;
}
.allConWrap .countryBox {
  padding: 15px;
  width: 20%;
}
.allConWrap .countryBox img,
.allCountTable img {
  width: 55px;
  margin-right: 10px;
}
.allConWrap .countryBox a,
.allCountTable a,
.allCountTable button {
  display: inline-block;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  background-color: transparent;
  border: 0;
}
.allConWrap .countryBox a:hover,
.allCountTable a:hover,
.allCountTable button:hover {
  color: #3f2566;
}
.allConWrap .countryBox:nth-of-type(odd),
.allCountTable tr:nth-of-type(odd) {
  background-color: #0000000d;
}
.allConSec .mainContTop {
  border-bottom: none;
}
.allCountTable {
  margin-bottom: 50px;
}
.logo {
  background: transparent;
  border: 0;
}
/* .allConWrap table{background:#fff;box-shadow:0 0 5px 5px #ece9e9;margin-bottom:50px;}
.allConWrap table td{border:none;}
.allConWrap table td img{width:55px;margin-right:10px;}
.allConWrap table td a{color:#000;font-size:14px;font-weight:500;}
.allConWrap table td a:hover{color:#3F2566;} */

/*---------------------scholarships page----------------*/

.scholarshipsPage .countryBanner {
  margin-bottom: 50px;
}
.scholarFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.scholarBox {
  border-top: 4px solid #3f2566;
  padding: 20px;
  height: 290px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 5px 2px #eaeaea;
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  width: 246px;
  position: relative;
}
.scholarBox h5 {
  border-bottom: none;
  padding-bottom: 0;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 21px;
  margin-bottom: 15px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.scholarBox p img {
  margin-right: 10px;
  display: inline-block;
  width: 20px;
}
.scholarBox p:nth-of-type(1) img {
  width: 30px;
}
.scholarBox p {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
}
.scholarBox .btn {
  background: #f9b316;
  color: #fff;
  margin: 20px auto 0;
  width: 90%;
  display: block;
}
.scholarBox.adBox {
  padding: 0;
}
.scholarBox.adBox .btn {
  width: 100%;
}
.scholarBox p:nth-of-type(3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.scholarBox p:nth-of-type(4) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

/*---------------------scholarships country----------------*/
.scholarshipCountryFlex {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.scholCountryPage .scolarshipCard {
  margin: 10px 10px;
  width: 218px !important;
}

/*---------------------scholarship detail page----------------*/
.scholarshipDetailPage {
  background-color: #fff;
}
.scholarshipDetailPage .countryBanner {
  margin-bottom: 50px;
}
.scholarDetailCont p.countryTitle {
  font-size: 23px;
}
.scholarDetailCont p.countryTitle img {
  margin-right: 5px;
  width: 39px;
}
.scholarDetailCont h1 {
  font-size: 26px;
  font-weight: 600;
  color: #3f2566;
  margin-bottom: 30px;
}
.scholarDetailCont h4 {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 15px;
}
.scholValueBox {
  background-color: #e9defa;
  padding: 20px;
  border-left: 5px solid #3f2566;
  margin-bottom: 40px;
  border-radius: 5px;
}
.scholValueBox p {
  margin-bottom: 0;
}
.greyCampWrap {
  background-color: #f7f8f9;
  padding: 30px;
  margin-bottom: 40px;
}
.greyCampFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
}
.greyCampFlex div {
  padding-left: 20px;
}
.greyCampFlex span {
  font-size: 12px;
  color: #707070;
  display: block;
}
.greyCampFlex img {
  width: 100px;
}
.greyCampFlex h5 {
  font-size: 17px;
  font-weight: 600;
}
.greyCampFlex p {
  margin-bottom: 0;
}
.scholarDetailCont ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 20px;
}
.scholarDetailCont ul li {
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
}
.scholarDetailCont ul li:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #f9b316;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.scholarDetailCont ul ul {
  padding-left: 20px;
}
.inquiryWrap {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.inquiryWrap .inquiryVector {
  z-index: 99;
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.inquiryWrap .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inquiryWrap > div {
  width: 50%;
  align-self: stretch;
}
.inquiryWrap .left {
  position: relative;
  background: url("https://assets.timescoursefinder.com/Media/inquiry-img.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-self: stretch;
}
.inquiryWrap .left:before {
  content: "";
  z-index: 9;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2c1d666b;
}
.inquiryWrap .left .content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  padding: 0 50px;
  text-align: center;
  z-index: 10;
}
.inquiryWrap .left .content h5 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
}
.inquiryWrap .left .content p {
  color: #fff;
}
.inquiryWrap .right {
  background-color: #4d3374;
  padding: 30px 50px;
}
.inquiryWrap .formWrap {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
.inquiryWrap .formWrap .form-group {
}
.loader-web {
  background-color: rgba(0, 0, 0, 0.247);
  position: fixed;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100%;
  left: 0;
}
.headTopNav .sociallinks li svg {
  width: 25px;
  height: 25px;
}
.headTopNav .sociallinks li {
  margin: 0 4px;
}
.sociallinks {
  display: flex;
}
.loader-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}
.header .headBottom button {
  background-color: transparent;
  border: 0;
}
.loaderCourse {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  margin: 0px auto;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .inquiryWrap .formWrap .form-control{background:#f5f5f5;border:none;height:40px;font-size:13px;padding:10px;} */
.phoneWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .inquiryWrap .formWrap textarea.form-control{height:85px;} */
.inquiryWrap .checkboxLabel {
  font-size: 12px;
}
.inquiryWrap .btn {
  background-color: #f9b316;
  color: #fff;
  margin: 0 auto;
  display: block;
  padding: 5px 20px;
}
.reviewFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.shareReviewWrap {
  background-color: #f7f8f9;
  padding: 50px 0;
}
.shareReviewWrap h5 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 25px;
  font-size: 19px;
}
.reviewFlex .right {
  width: 70%;
  padding-left: 30px;
}
.reviewFlex .form-control {
  background-color: #fff;
  border: none;
  font-size: 13px;
  padding-left: 10px;
}
.reviewFlex input.form-control {
  height: 40px;
}
.reviewFlex .btn {
  background-color: #f9b316;
  color: #fff;
}
.sharePageWrap {
  background-color: #f7f8f9;
  border-top: 1px solid #707070;
}
.sharePageFlex {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sharePageFlex p {
  color: #031029;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.sharePageFlex ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sharePageFlex ul li {
  display: inline-block;
  margin: 0 5px;
}
.sharePageFlex ul li a {
  display: inline-block;
}
.sharePageFlex ul li a img {
  width: 30px;
}
.scholarDetailCont .tableWrap > h6 {
  background-color: #f9b316;
}
.scholarDetailCont .tableWrap .table td:nth-of-type(1) {
  padding-left: 30px;
}
.customCheckbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #707070;
  font-size: 14px;
}
.customCheckbox input[type="checkbox"] {
  flex-shrink: 0;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  position: relative;
  margin-top: 3px;
  -webkit-appearance: none;
  background: transparent;
  border-width: 1px;
  border-radius: 2px;
}
.customCheckbox input[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  background: url("/src/static/images/unchecked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 15px;
  height: 15px;
}
.customCheckbox input[type="checkbox"]:checked::before {
  border-radius: 2px;
  background: url("/src/static/images/checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.customCheckbox input[type="checkbox"].is-invalid::before {
  border-radius: 2px;
  background: url("/src/static/images/checkbox-error.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.customCheckbox input[type="checkbox"].is-invalid {
  padding: 0;
}
.customCheckbox span a {
  color: #3f2566;
}
.checkboxGroup .custom-radio {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #707070;
  font-size: 14px;
}
.checkboxGroup .custom-radio input[type="radio"] {
  flex-shrink: 0;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  position: relative;
  margin-top: 3px; /* -webkit-appearance: none; */
  background: transparent;
  border-width: 1px;
  border-radius: 2px;
}

/* .checkboxGroup .custom-radio input[type="radio"]::before{content:"";position:absolute;left:-1px;top:-1px;} */
.checkboxGroup .custom-radio input[type="radio"]:checked {
  -webkit-appearance: none;
}
.checkboxGroup .custom-radio input[type="radio"]:checked::before {
  width: 15px;
  height: 15px;
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 2px;
  background: url("/src/static/images/radio-checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/*----------------------compare course page----------*/
.compareCoursePage .countryBanner {
  margin-bottom: 50px;
}
.compareCourseWrap .uniLogo {
  width: 100px;
}
.compareCourseWrap td {
  border-left: 15px solid #fff;
}
.compareCourseWrap td:nth-of-type(1) {
  padding-left: 30px;
  color: #000;
  font-weight: 600;
  text-align: left;
  border-left: none;
}
.compareCourseWrap td {
  width: 25%;
}
.compareCourseWrap .tableWrap {
  margin-bottom: 0;
  box-shadow: 0 0 2px 2px #eae9e9;
}
.compareCourseTop {
  text-align: center;
  margin-bottom: 30px;
}
.compareCourseTop h5 {
  font-size: 26px;
}
.compareCourseTop h6 {
}
.compareCourseTop p {
  color: #707070;
}
.compareFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.compareFilters .dropdown {
  width: 31.5%;
  margin: 10px;
}
.compareFilters .dropdown .btn {
  width: 100%;
  background: #fff;
  color: #161615;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 15px;
  font-size: 15px;
}
.compareFilters .dropdown-toggle::after {
  color: #3f2566;
  font-size: 20px;
}
.compareCourseWrap .tableWrap .table td a {
  color: #4fb7dd;
}
.compareCourseWrap .tableWrap > h6 {
  margin-bottom: 0;
}
.compareCourseTop .btnGroup .btn {
  width: 250px;
  margin: 0 15px;
  padding: 7px 10px;
}
.compareCourseTop .btnGroup .btn:disabled:hover {
  opacity: 0.65;
  background-color: #f9b316;
  border: 1px solid #f9b316;
  color: #fff;
}
.compareCourseWrap .tableWrap:nth-last-of-type(1) {
  margin-bottom: 50px;
}
.courseDesc {
  background: #fff;
  padding: 30px;
}
.twelveBox .imgWrap button {
  padding: 0;
}
.tran-button {
  background-color: transparent;
  border: 0;
}
/*----------------------blog page----------*/
.blogFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin:0 -10px 50px; */
  flex-wrap: wrap;
}
.blogBox {
  position: relative;
  align-self: stretch;
  margin: 10px;
  width: 256.5px;
  box-shadow: 0 0 5px 2px #eae8e8;
  border-radius: 5px;
  padding-bottom: 50px;
}
.blogBox .imgWrap {
  height: 155px;
}
.blogBox .imgWrap img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.blogBox .content {
  padding: 10px;
}
.blogBox h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
}
.blogBox > p {
}
.blogBox .btn {
}
.blogInfoFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.blogInfoFlex p {
  color: #707070;
  margin-right: 15px;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.blogInfoFlex p:nth-of-type(2) {
  margin-right: 0;
}
.blogInfoFlex p img {
  width: 11px;
  margin-right: 5px;
  display: inline-block;
  margin-top: 4px;
}
.blogBox .btn {
  font-size: 13px;
  background-color: #f9b316;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.blogBox:hover {
  box-shadow: 0 0 5px 5px #cecdcd;
}
.blogDetailCont {
  margin-bottom: 50px;
}
.blogBox .content > p {
  margin-bottom: 0;
  font-size: 13px;
}
.blogSlider .slick-track {
  display: flex;
}
.blogSlider .slick-slide {
  height: inherit;
}
.blogSlider .slick-slide > div {
  height: 100%;
  padding-bottom: 20px;
}
.blogSlider .slick-slide .blogBox {
  height: inherit;
  margin: 10px auto;
}

/*----------------------group universities page----------*/
.uniGroupTop h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}
.uniGroupTop .countrySearchWrap {
  margin-bottom: 40px;
}
.uniGroupFilters {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.uniGroupFilters .form-control {
  width: 23%;
  margin: 0 10px 15px;
  border: none;
  padding: 10px;
  height: 45px;
  font-size: 13px;
}
.uniGroupFlex {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -10px 50px;
}
.uniGroupBox {
  height: 317px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 5px 5px #eaeaea;
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  width: 246px;
}
.uniGroupBox .imgWrap {
  position: relative;
  height: 100px;
}
.uniGroupBox img {
  width: 85px;
  height: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uniGroupBox p {
  text-align: center;
  font-size: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uniGroupBox .btn {
  background: #3f2566;
  color: #fff;
  font-size: 13px;
  margin: 0 auto 20px;
  display: block;
}
.uniGroupBox h6 {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.groupSliderWrap .logoBox {
  width: 250px;
  margin: 0 auto;
  display: block;
}
.uniSliderWrap .uniGroupBox {
  margin: 10px auto;
}
.uniSliderWrap .courseBoxCont {
  padding-bottom: 10px;
}

/*----------------------all groups page----------*/
.allGroupBanner {
  position: relative;
  margin-bottom: 50px;
}
.allGroupBanner .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.allGroupBanner h1 {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  color: #fff;
}
.allGroupBanner h2 {
  font-weight: 600;
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
}
.allGroupBanner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2c1d666b;
}
.allGroupsPage .logoFlex {
  justify-content: center;
  margin-bottom: 50px;
}
.allGroupsPage .logoBox {
  margin: 10px;
}

/*----------------------blog detail page----------*/
.authorInfo {
  margin-top: -50px;
  text-align: center;
  position: relative;
}
.authorInfo .blogInfoFlex {
  justify-content: center;
}
.authorInfo > img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-bottom: 10px;
  display: inline-block;
}
.authorInfo h6 {
}
.blogDetailCont h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.blogDetailCont p.introP {
  color: #707070;
  font-style: italic;
  max-width: 700px;
  text-align: center;
  margin: 0 auto 40px;
}
.blogDetailBox {
  background-color: #fff;
  border-left: 4px solid #3f2566;
  padding: 20px;
  margin-bottom: 30px;
}
.blogDetailBox p {
  margin-bottom: 0;
  border-radius: 5px;
}
.blogDetailCont ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 20px;
}
.blogDetailCont ul li {
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
}
.blogDetailCont ul li:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #f9b316;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.blogDetailCont img {
  float: left;
  width: 325px;
  margin: 0 30px 10px 0;
}
.blogBtn {
  background-color: #f9b316;
  color: #fff;
  padding: 7px 25px;
  margin: 0 auto;
  display: block;
  font-weight: 500;
}

/*------------------------------------*/
.flip-card {
  padding: 0;
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1; /*perspective:1000px;*/
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s linear;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  /*transform:rotateY(180deg);*/
}
.flip-card:hover .flip-card-front {
  opacity: 0;
}
.flip-card:hover .flip-card-back {
  opacity: 1;
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%; /*-webkit-backface-visibility:hidden; backface-visibility:hidden;*/
}
.flip-card-front {
  background-color: #bbb;
  color: black;
  transition: linear 0.5s all;
}
.flip-card-back {
  background-color: #f9b316;
  color: white; /*transform:rotateY(180deg);*/
  opacity: 0;
  transition: linear 0.5s all;
}

/*---------------------Modals-----------------------*/
.modal-title {
  font-size: 19px;
  font-weight: 600;
}
.compareModal .sidebar {
  width: 268px;
}

/*-----------------login signup forms------------------*/
.signinModal {
  overflow-y: auto;
}
.signinModal .modal-body {
  padding: 50px 40px 20px;
  position: relative;
}
.signinModal .close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.signinModal .close img {
  width: 15px;
}
.signinModal h3 {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
}
.signinModal h3 + p {
  text-align: center;
}
.signinModal .form-group label {
  font-size: 14px;
  color: #707070;
  margin-bottom: 5px;
  font-weight: 500;
}
.signinModal .form-group label img {
  margin-right: 5px;
  width: 20px;
}
.signinModal .form-group label a {
  color: #f9b316;
  margin: 0 5px;
  font-size: inherit;
}

/* slect placeholder color in profile page */
.contactWrapper .css-1wa3eu0-placeholder {
  color: black !important;
}

/* .signinModal .form-group .form-control{background:#E9DEFA;border:1px solid #3F2566;height:35px;} */
.formBtn1 {
  background: #3f2566;
  color: #fff;
  width: 300px;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.formBtn {
  background: #f9b316;
  color: #fff;
  width: 300px;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.formBtn:hover {
  opacity: 0.8;
  color: #fff;
}
.formBtn:disabled {
  opacity: 0.65;
}
.formBtn:disabled:hover {
  opacity: 0.65;
  background: #f9b316;
  color: #fff;
}
.formBtn1:hover {
  border: 1px solid #3f2566;
  opacity: 0.9;
  color: #fff;
}
.forgetP {
  text-align: center;
}
.forgetP a {
  color: #3f2566;
  font-weight: 500;
}
.orP {
  position: relative;
  width: max-content;
  margin: 0 auto 30px;
}
.orP::before {
  content: "";
  position: absolute;
  left: -160px;
  top: 50%;
  width: 150px;
  height: 1px;
  background-color: #ccc;
}
.orP::after {
  content: "";
  position: absolute;
  right: -160px;
  top: 50%;
  width: 150px;
  height: 1px;
  background-color: #ccc;
}
.signinModal ul {
  padding: 0;
  margin: 0 0 30px;
  text-align: center;
  list-style-type: none;
}
.signinModal ul li {
  display: inline-block;
  margin: 0 15px;
}
.signinModal ul li a {
  display: inline-block;
}
.signinModal ul li a img {
  width: 60px;
}
.signinModal ul li a:hover {
  opacity: 0.8;
}
.noAccP,
.disciplineInfoBanner p.noAccP {
  text-align: center;
  margin-bottom: 0;
}
.noAccP a {
  color: #f9b316;
  font-weight: 500;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  background: #e9defa !important;
}
.eligibility-form-control.form-control {
  display: inline-flex;
  max-width: 185px;
  height: 30px;
  padding-left: 10px;
}
.scoreRow .eligibility-form-control.form-control {
  max-width: 100%;
}

/*----------------why section forms---------*/
.whyFormModal .modal-dialog {
  max-width: 1190px;
}
.formSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.whyRegSide {
  width: 245px;
  text-align: center;
}
.whyRegSide h5 {
  font-weight: 600;
  margin-bottom: 20px;
}
.whyBoxWrap > div {
  background-color: #e9defa;
  padding: 15px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.whyBoxWrap h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.whyBoxWrap img {
  margin-bottom: 15px;
}
.formSide {
  text-align: left;
  flex: 1 1 auto;
  padding-right: 40px;
  border-right: 1px solid #707070;
  margin-right: 40px;
  align-self: stretch;
}
.eligibilityEducation h5 {
  font-weight: 500;
}

/* .eligibilityEducation .flex{display:flex;justify-content:flex-start;align-items:flex-start;}
.eligibilityEducation .flex p{max-width:400px;margin-right:20px;} */
.whyRegSide.signupForm {
  width: 400px;
}
.whyRegSide.signupForm .whyBoxWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.signupForm .whyBoxWrap > div {
  width: 49%;
  align-self: stretch;
}
.eligibilityEducation .eligibility-form-control.form-control {
  height: 45px;
}

/*----------------account pages---------*/
.accountPage {
  padding: 50px;
  background: #e9defa;
}
.accountPage .logo {
  display: inline-block;
  margin-bottom: 50px;
}
.accountPage .logo img {
  width: 200px;
}
.accountBox {
  width: 450px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  padding: 50px 20px 20px;
  box-shadow: 0 0 5px 5px #e6e6e6;
  position: relative;
}
.resetImg {
  margin: 0 auto 20px;
  width: 100px;
  display: block;
}
.resendOtpBtn {
  background: transparent;
  border: none;
  width: max-content;
  margin: 0 auto;
  display: block;
  font-size: 14px;
  padding: 0;
}
.resendOtpBtn:hover {
  opacity: 1;
}
.otpWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.otpWrap input {
  width: 60px !important;
  height: 50px;
  border-radius: 23px;
  background: #e6e6e6;
  border: none;
  margin: 0 10px;
  color: #000;
  text-align: center;
}
.otpWrap input::placeholder {
  color: #b7c4cb;
}
.otpWrap input:focus {
}
.errorOtp.MuiAlert-root {
  justify-content: center;
  margin-bottom: 10px;
}

/*----------------logged in header---------*/

/* .loginHeader{border-top:7px solid #3F2566;} */
.loginHeadRight {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.loginHeadRight .headBtn {
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
.loginHeadRight .headBtn img {
  width: 27px;
}
.loginHeadRight .dropdown {
  margin-left: 10px;
}
.loginHeadRight .dropdown .btn {
  background: transparent;
  padding: 0;
  width: auto;
  margin: 0;
  border: none;
}
.loginHeadRight .dropdown .btn img {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0px 1px #3f2566;
}
.loginHeadRight .dropdown .btn:hover {
  background: transparent;
  border: none;
  color: #fff;
}
.loginHeadRight .dropdown .dropdown-menu {
  left: auto !important;
  right: 19px;
  top: 128% !important;
  transform: none !important;
  border: none;
  box-shadow: 0 0 5px 5px #d6d6d6cc;
}
.loginHeadRight .dropdown .dropdown-item {
  font-size: 13px;
  padding: 5px 10px;
}
.loginHeadRight .dropdown .dropdown-item img {
  width: 15px;
  margin-right: 10px;
}
.loginHeadRight .dropdown .dropdown-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 7px;
  border-bottom: 10px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

/*----------------app detail page---------*/
.appDetailPage .countryBanner,
.shortlistPage .countryBanner {
  margin-bottom: 50px;
}
.recomendCourseBox {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f8f9;
}
.recomendCourseBox img {
  width: 100px;
  margin-right: 10px;
}
.recomendCourseBox h6 {
  color: #3f2566;
  font-size: 15px;
  margin-bottom: 5px;
}
.recomendCourseBox p {
  margin-bottom: 0;
}
.appDetailPage h2 {
  font-weight: 500;
  font-size: 19px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.tag {
  padding: 2px 15px;
  border-radius: 0px;
  color: #000;
  font-size: 12px;
  display: inline-block;
}
.tag.accepted {
  background: #a6ffaf;
}
.tag.rejected {
  background: #f44336;
  color: #fff;
}
.appDocWrap {
  box-shadow: 0 0 5px 2px #eaeaea;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 50px;
}
.appDocWrap h2 {
  border-bottom: none;
  text-align: center;
}
.appDocWrap ul {
  padding: 0;
  margin: 0 0 50px;
  list-style-type: none;
}
.appDocWrap ul li {
  padding-left: 30px;
  position: relative;
  font-size: 15px;
  margin-bottom: 15px;
}
.appDocWrap ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  background: url("/src/static/images/doc-not-uploaded.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.appDocWrap ul li.uploaded:before {
  background: url("/src/static/images/doc-uploaded.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.appDocWrap .btn {
  margin: 0 auto;
  display: block;
  padding: 5px 30px;
}

/*----------------shortlist page---------*/
.shortlistPage {
  margin-bottom: 50px;
}
.shortlistPage .table {
  background-color: #fff;
  margin-bottom: 50px;
}
.shortlistPage .table tr {
  margin-bottom: 5px;
}
.shortlistPage thead tr {
  background-color: #3f2566;
  border-bottom: 5px solid #f5f5f5;
}
.shortlistPage thead tr th {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.shortlistPage tbody tr td {
  font-size: 14px;
}
.delBtn {
  background-color: transparent;
  border: none;
  padding: 0;
}
.shortlistPage tbody tr.total {
  background-color: #e9defa;
}
.shortlistPage tbody tr.total td {
  color: #3f2566;
  text-transform: uppercase;
  font-weight: 500;
}
.mainContTop.trackAppTop .sortFilter {
  width: 100%;
}
.mainContTop.trackAppTop h5 {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.promoTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promoTotal h6 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.promoTotal p {
  margin-bottom: 0;
  color: #3f2566;
  font-weight: 500;
}
.promoTotal:nth-last-of-type(1) {
  border-top: 1px solid #707070;
  padding-top: 15px;
  margin-bottom: 30px;
}

/*----------------uploadFileWrap---------*/
.uploadFileWrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.uploadFileWrap .box {
  width: 47%;
  align-self: stretch;
  margin: 0 15px 10px;
}
.uploadFileWrap .box > h6 {
  font-size: 16px;
  margin-bottom: 15px;
}

/*file upload global*/
.file-upload {
  width: 100%;
  margin: 0 auto 5px;
}
.file-upload-content {
  text-align: center;
  display: flex;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-wrap {
  position: relative;
  background: #fff;
  border: 0.5px dashed #603f94;
  border-radius: 3px;
  margin-bottom: 10px;
}
.image-dropping,
.image-upload-wrap:hover {
  background-color: #ececec;
}
.image-title-wrap {
  padding: 0;
  color: #222;
}
.drag-text {
  text-align: center;
  padding: 35px 0 15px;
}
.drag-text img {
  margin-bottom: 15px !important;
  width: 90px !important;
}
.drag-text h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #002642;
  margin-bottom: 0;
}
.drag-text h3 + p {
  margin-bottom: 0;
  color: #707070;
}
.drag-text h4 {
  display: inline-block;
  color: #fff;
  background: #3f2566;
  padding: 8px 20px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
}
.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}
.remove-image {
  margin: 0 0 0 10px;
  color: #fff;
  background: #bfbdbd;
  border: none;
  padding: 1px 6px;
  border-radius: 4px;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
}
.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}
.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}
.image-title-wrap p {
  display: inline-block;
  margin-bottom: 0;
}

/*======================footer pages----------------*/
.staticWrapper {
  padding-bottom: 50px;
}
.staticHeader {
  background-color: #f5f5f5;
  padding: 50px 0;
  text-align: center;
  margin-bottom: 50px;
}
.staticHeader p {
  margin-bottom: 0;
}
.staticHeader h6 {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;
}
.staticHeader h6 span {
  color: #f9b316;
}
.staticPage {
  background-color: #fff;
}
.staticPage h4 {
  font-weight: 700;
  font-size: 17px;
  color: #000;
  margin-bottom: 20px;
}
.staticPage p {
  color: #000;
  line-height: 25px;
}
.staticPage a {
  color: #3f2566;
  font-weight: 500;
}
.staticPage a:hover {
  text-decoration: underline;
}
.staticPage ul {
  padding: 0 0 0 20px;
  margin: 0 0 20px;
  list-style-type: none;
}
.staticPage ul li {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
  position: relative;
  line-height: 25px;
}
.staticPage ul li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #f9b316;
  position: absolute;
  left: -14px;
  top: 9px;
}
.staticPage .box {
  margin-bottom: 40px;
}
.staticPage h5 {
  color: #3f2566;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 23px;
}
.staticPage h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 25px;
}
.staticPage ul.addressUl {
  padding: 0;
}
.staticPage ul.addressUl li {
  font-weight: 500;
}
.staticPage ul.addressUl li:before {
  display: none;
}
.staticPage p b {
  font-weight: 500;
  color: #f9b316;
}
.contactWrapper {
  background-color: #fff;
  padding: 50px 80px;
  box-shadow: 0 0 5px 5px #ececec;
  border-radius: 5px;
}
.contactWrapper h5 {
  text-align: center;
}
.contactWrapper p {
  text-align: center;
  margin-bottom: 40px;
}
.contactWrapper .form-group {
  margin-bottom: 30px;
}
.contactWrapper .form-control {
  height: 45px;
  background-color: #f7f8f9;
  border: 1px solid #bcbcbc;
}
.contactWrapper textarea.form-control {
  height: 160px;
}
.contactWrapper label {
  color: #707070;
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}
.contactWrapper label img {
  width: 19px;
  margin-right: 5px;
}
.contactWrapper label span,
.contactWrapper h6 span {
  color: #fd0d1b;
  font-size: 17px;
  margin-left: 2px;
  line-height: 1;
}
.contactWrapper .formBtn {
}
.contactWrapper .css-yk16xz-control,
.contactWrapper .css-1pahdxg-control {
  border: 1px solid #bcbcbc !important;
  background-color: #f7f8f9 !important;
  height: 45px;
  box-shadow: none;
}
.contactWrapper h6 {
  font-size: 14px;
  margin-bottom: 10px;
}
.contactWrapper .customCheckbox span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.contactWrapper .customCheckbox {
  margin-bottom: 50px;
}
.careerWrapper h4,
.marketingWrapper h4 {
  font-weight: 600;
  font-size: 21px;
  color: #000;
  margin-bottom: 40px;
  text-align: center;
}
.contactWrapper .dpWrapper {
  margin-bottom: 40px;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}
.contactWrapper .dpWrapper .btn {
  position: absolute;
  right: 7px;
  bottom: -9px;
}
.contactWrapper .dpWrapper > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.hiddenfile {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.careerTop h5 {
  font-weight: 600;
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
}
.careerTop p {
}
.careerValueFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 70px;
}
.careerValueFlex .box {
  background-color: #fff;
  padding: 30px 15px;
  box-shadow: 0 0 5px 5px #ececec;
  border-radius: 5px;
  width: 31%;
  text-align: center;
  align-self: stretch;
}
.careerTop {
  margin-bottom: 70px;
}
.careerValueFlex h6 {
  margin-bottom: 15px;
}
.careerValueFlex img {
  width: 60px;
  margin-bottom: 20px;
}
.careerValueFlex p {
  margin-bottom: 0;
  font-size: 13px;
}
.visionFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.visionFlex .left {
  padding: 40px 60px;
  text-align: center;
  width: 40%;
  align-self: stretch;
  background: #3f2566;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visionFlex .left img {
  z-index: 9;
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translate(0, -50%);
  width: 50px;
}
.visionFlex h6 {
  color: #fff;
  font-size: 22px;
}
.visionFlex p {
  color: #fff;
}
.visionFlex p a {
  color: #fff;
  font-weight: 600;
}
.visionFlex .right {
  flex: 1 1 auto;
  padding: 60px 40px 40px;
  text-align: center;
  position: relative;
  background: url("https://assets.timescoursefinder.com/Media/vision-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.visionFlex .right:before,
.marketingBottom:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3f2566c7;
}
.visionFlex .right .cont {
  z-index: 9;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.visionFlex .left .cont {
  max-width: 380px;
  margin: 0 auto;
}
.visionFlex h5 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 15px;
}
.visionFlex h5 img {
  margin-left: 10px;
  width: 15px;
}
.marketingWrapper p {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 35px;
}
.marketingWrapper h5 {
  color: #3f2566;
  font-weight: 600;
  text-align: center;
  margin: 0 auto 35px;
  max-width: 630px;
  font-size: 18px;
  line-height: 26px;
}
.marketingFlex {
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.marketingFlex .box {
  width: 247px;
  margin: 15px;
  height: 210px;
  align-self: stretch;
  text-align: center;
  background-color: #fff;
  padding: 40px 25px 25px;
  box-shadow: 0 0 5px 5px #ececec;
  border-radius: 5px;
}
.marketingFlex .box img {
  margin-bottom: 20px;
}
.marketingFlex .box p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 15px;
}
.marketingBottom {
  padding: 55px 0;
  position: relative;
  background: url("https://assets.timescoursefinder.com/Media/marketing-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.marketingBottom .cont {
  position: relative;
  z-index: 9;
  text-align: center;
}
.marketingBottom h5 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 22px;
}
.marketingBottom p {
  color: #fff;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
}
.marketingBottom p a {
  font-weight: 600;
  color: #fff;
}
.applyWrap p,
.applyWrap h4 {
  text-align: center;
}
.applyWrap ul {
  margin-bottom: 40px;
}
.marketingBottom.applyBottom {
  margin-top: 40px;
}
.marketingBottom.applyBottom .cont img {
  margin-bottom: 20px;
  width: 45px;
}
.marketingBottom.applyBottom .cont h5 {
  margin-bottom: 5px;
}
.marketingBottom.applyBottom {
  padding: 35px 0;
}

/*------------------------custom tabs--------------------*/
.customTabs .react-tabs__tab-list {
  border-bottom: none;
  margin-bottom: 10px;
}
.customTabs .react-tabs__tab {
  background-color: #e9defa;
  color: #707070;
  font-size: 14px;
  border-radius: 0;
  padding: 8px 30px;
  border: none;
  border-right: 1px solid #d2d2d2;
}
.customTabs .react-tabs__tab:nth-last-of-type(1) {
  border-right: none;
}
.customTabs .react-tabs__tab--selected {
  background-color: #3f2566;
  color: #fff;
}

/*--------------------country sort----------------*/
.countrySort {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.countrySort p {
  margin-bottom: 0;
  font-weight: 600;
}
.countrySort .btn {
  padding: 0;
  margin-left: 20px;
  font-size: 15px;
  color: #000;
}
.countrySort .btn.active {
  color: #f9b316;
}
.countrySort .btn img {
  width: 18px;
  margin-left: 5px;
}
.countrySort .btn.active img {
  transform: rotate(180deg);
  color: #f9b316;
}

/*--------------------full width screens----------------*/
.fullWidth {
  margin-bottom: 50px;
}

/* .fullWidth .twelveBox{width: 262px;}
.fullWidth .twelveBox .imgWrap{height: 160px;} */
.fullWidth .scholarBox {
  width: 262px;
}
.fullWidth .courseBox {
  width: 262px;
}

/*---------------filter sidebar------------*/
.filterNavBtn,
.filterNavBtn:hover {
  background-color: #3f2566;
  border: 1px solid #3f2566;
  color: #fff;
  margin-left: 10px;
}
.filterNavBtn img {
  width: 20px;
  margin-right: 5px;
}
.filterNav {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  background-color: #fff;
  display: none;
  overflow-y: auto;
}
.filterNav.show {
  display: block;
}
.closeFilterNav {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  padding: 0;
}
.filterNavBtn {
  display: none;
}
.applyFiltBtn {
  display: block;
  margin: 0 auto;
  min-width: 80%;
}

/* course grid modal */
.coursesModal .courseFlex {
  justify-content: center;
}

/*=======================================Updates=========================================*/

/* share page btn and modal */
.sharePgBtn {
  margin-left: 10px;
}
.sharePgBtn img {
  margin-right: 5px;
  width: 15px;
}
.shareSearch {
  display: flex;
  align-items: center;
  border: 2px solid #3f2566;
  border-radius: 8px;
  margin-bottom: 35px;
}
.shareSearch .btn {
  padding: 5px 20px;
  flex-shrink: 0;
  height: 45px;
  border-radius: 0 8px 8px 0;
  font-weight: 600;
}
.shareSearch input {
  border: none;
  border-radius: 8px 0 0 8px;
  padding: 9px 10px 9px 10px;
}
.sharePageModal.signinModal h3 {
  margin-bottom: 30px;
}
.sharePageModal .modal-dialog {
  max-width: 620px;
}
.sharePageModal p {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #031029;
}
.signinModal ul li a img {
  width: 40px;
}

/* university individual page campus checkboxes */
.uniIndFilters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.uniIndFilters h6 {
  flex-shrink: 0;
  margin-right: 30px;
}
.uniCheckArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
}
.uniCheckArea > div {
  width: 30%;
  margin-bottom: 15px;
  padding-right: 5px;
}

/* site map screen */
.sitemapFilters {
  padding: 0 20px;
}
.sitemapCard {
  box-shadow: 0 0 5px 2px #eaeaea;
  background: #fff;
  padding: 20px;
  margin-bottom: 15px;
}
.sitemapCard h5 {
  color: #3f2566;
  font-weight: 500;
}
.sitemapCard h6 {
  color: #707070;
  word-break: break-word;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
}
.sitemapCard p {
  color: #000000;
  margin-bottom: 0;
  font-size: 15px;
}
.siteLoadBtn {
  min-width: 200px;
  margin: 0 auto;
  display: block;
}

/* compare swipper */
.compareSwiper {
  position: fixed;
  bottom: 0;
  width: 100%;
}

/*======media queries==========*/

/* @media (min-width:768px){
    .main-menu-nav .menu-toggler,.main-menu-nav .menu-header{display:none}
    .main-menu-div>ul>li{border-top-left-radius:3px;border-top-right-radius:3px;padding:0px;margin:0 35px;position:relative;}
    .main-menu-div>ul>li>a{padding:0 0;line-height:1;text-transform:capitalize;}
    .main-menu-div>ul>li>a:hover,.main-menu-div>ul>li>a.active{color:#F9B316;}
    .main-menu-div>ul>li:hover,.main-menu-div>ul>li>a.current-menu-item,.main-menu-div ul li ul{}
    .main-menu-div ul li ul li a:hover{}
    .main-menu-div>ul>li:nth-last-child(1){padding-right:0}
} */
.searchBlog {
  margin: 0 auto 0px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}
.searchBlog input {
  border: 0;
  width: 100%;
  height: 70px;
  padding: 0px 15px;
}
.searchBlog input:focus {
  border: 0;
  outline: 0;
}
@media (max-width: 1199px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 15px;
  }
  .headTopNav ul li a,
  .headTopNav ul li button {
    font-size: 14px;
  }
  .homePage .courseBanner {
    height: 350px;
  }
  .searchWrap,
  .advFilter {
    width: 890px;
  }
  .searchBox {
    padding: 20px 20px 15px;
  }
  .searchBox .css-1wa3eu0-placeholder,
  .css-b8ldur-Input input {
    font-size: 14px;
  }
  .disciplineFlex .left h1 {
    font-size: 27px;
  }
  .disciplineFlex .left p {
    font-size: 20px;
    line-height: 26px;
  }
  .courseBox {
    /*height:438px;*/
    width: 290px;
  }
  .twelveBox {
    width: 217px;
  }
  .twelveBox .imgWrap {
    height: 133px;
  }
  .twelveBox .titleBox h6 {
    font-size: 13px;
  }
  .twelveBox .dropdown button.btn {
    font-size: 13px;
  }
  .mainContTop h5 {
    line-height: 23px;
  }
  .appProcessSec h5 {
    margin-bottom: 50px;
  }
  .appProcessSec {
    padding: 50px 0;
  }
  .appProcessBox {
    padding: 30px 15px;
  }
  .appProcessBox h6 {
    font-size: 14px;
  }
  .appProcessBox img {
    width: 55px;
    margin-bottom: 10px;
  }
  .counselWorkBox img {
    width: 90px;
    margin: 0 auto 20px;
  }
  .counselWorkBox:before {
    top: 44px;
    right: -45px;
    width: 70px;
  }
  .counselWorkBox {
    width: 15%;
  }
  .counselWorkBox h6 {
    font-size: 14px;
  }
  .counselWorkBox p {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .allConWrap .countryBox img {
    width: 40px;
  }
  .scholarBox {
    width: 290px;
  }
  .uniGroupBox {
    width: 290px;
  }
  .leadBox {
    width: 175px;
    height: 175px;
  }
  .futureLeadSec .left {
    width: 50%;
  }
  .futureLeadSec .right {
    width: 45%;
  }
  .disciplineInfoBanner {
    padding: 35px 0;
  }
  .uniGroupFilters .form-control {
    width: 22.8%;
  }
  .allGroupBanner h1 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .allGroupBanner h2 {
    font-size: 21px;
  }
  .allGroupsPage .logoBox {
    margin: 10px;
    width: 250px;
  }
  .topRankedSec .logoBox:nth-last-of-type(1) {
    display: none;
  }
  .topRankedLogoFlex > div.logoFlex {
    width: 36%;
  }
  .topRankedLogoFlex .topRankBtn {
    width: 63.7%;
  }
  .studyAbroadFlex:before {
    width: 815px;
  }
  .compareModal .modal-xl {
    max-width: 962px;
  }
  .searchBox input {
    font-size: 14px;
  }
  .css-g1d714-ValueContainer {
    padding-right: 20px !important;
  }
  .careerValueFlex h6 {
    font-size: 16px;
  }
  .courseBanner h1 {
    font-size: 28px;
    line-height: 34px;
  }
  .courseBanner h1 span {
    font-size: 22px;
  }
  .compareFilters .dropdown {
    width: 30.5%;
  }
  .headTopNav ul li {
    margin: 0 15px;
  }
  .regionModal .modal-dialog {
    max-width: 940px;
  }
  .regionFlex .right ul li {
    width: 29%;
  }
  .regionFlex.regionCourseFlex .right ul li {
    width: 45%;
  }
  .universityList .imgWrap {
    width: 150px;
    padding: 15px;
  }
  .universityList .imgWrap img {
    width: 115px;
  }
  .universityList .contentWrap {
    padding: 20px 10px;
  }
  .uniListFoo .btnGroup .btn {
    padding: 5px 5px;
    margin: 0 2px;
    width: 65px;
  }
  .whyFormModal .modal-dialog {
    max-width: 960px;
  }
  .eligibilityEducation .flex p {
    max-width: 280px;
    margin-right: 20px;
  }
  .formSide {
    padding-right: 20px;
    margin-right: 20px;
  }
  .sortFilter .primaryBtn {
    font-size: 13px;
  }
  .fullWidth .scholarBox {
    width: 217px;
  }
  .fullWidth .courseBox {
    width: 217px;
  }
  .scholarBox h5 {
    line-height: 19px;
    font-size: 14px !important;
  }
  .sitemapCard p {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .homePage .courseBanner {
    height: 320px;
  }
  .btn {
    font-size: 13px;
  }
  .courseFlex {
    margin: 0 auto;
    width: 572px;
    justify-content: flex-start;
  }
  .mainContent {
    padding-left: 0;
    width: 100%;
  }
  .mobTopbar {
    display: block;
  }
  .sidebar {
    width: 100%;
    margin-bottom: 40px;
  }
  .mainFlex {
    flex-direction: column;
  }
  .loginHeadRight .headBtn {
    margin: 0 3px;
  }
  .loginHeadRight .headBtn img {
    width: 23px;
  }
  .topSearch .button {
    width: 35px;
    height: 35px;
  }

  /* .searchWrap .searchBox:nth-of-type(2){display:none;} */
  .searchWrap,
  .advFilter {
    width: 700px;
  }
  .searchWrap .btn {
    font-size: 27px;
  }
  .searchBox input.form-control {
    font-size: 13px;
  }
  .searchBox {
    width: 28%;
  }
  /* .searchBox .css-1wa3eu0-placeholder,.css-b8ldur-Input input{font-size:13px;} */
  .searchBox .dropdown-menu a {
    font-size: 12px;
    padding: 5px 5px;
  }
  .searchBox .locationBtn img {
    width: 15px;
  }
  .searchItemBtn a:nth-of-type(1) {
    padding: 0;
  }
  .searchItemBtn a:nth-of-type(2) {
    padding: 0 5px;
  }
  .header .logo img {
    width: 200px;
  }
  .headBottom .right .btn {
    width: 80px;
  }
  .main-menu-div > ul > li > a img,
  .main-menu-div > ul > li > button img {
    width: 30px;
  }
  .main-menu-div > ul > li {
    margin: 0 15px;
  }
  .courseBanner h1 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  .courseBanner h1 span {
    font-size: 19px;
  }
  .courseBanner h2 {
    font-size: 20px;
  }
  .main-menu-div > ul > li > a,
  .main-menu-div > ul > li > button {
    font-size: 14px;
  }
  .headBottom .right .btn {
    margin-left: 10px;
  }
  .disciplineInfoBanner h1,
  .disciplineInfoBanner h3 {
    font-size: 22px;
  }
  .disciplineFlex .left a img {
    width: 160px;
  }
  .courseBox {
    /*height:422px;*/
    width: 266px;
  }
  .disciplineFlex .left h1 {
    font-size: 23px;
  }
  .disciplineFlex .left p {
    font-size: 17px;
    line-height: 24px;
  }
  .disciplineFlex .right {
    padding: 10px;
    width: 71%;
  }
  .disciplineBanner {
    height: 310px;
  }
  .factBox label {
    font-size: 14px;
  }
  .factBox p {
    font-size: 12px;
  }
  .factBox {
    padding: 5px 10px;
  }
  .factBox img {
    display: none;
  }
  .factBox div {
    padding-left: 0;
  }
  .disciplineFlex .right h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .disciplineInfoBanner {
    padding: 25px 0;
  }
  .twelveFlex {
    margin: 0 auto;
    width: 572px;
  }
  .twelveBox .imgWrap {
    height: 163px;
  }
  .twelveBox {
    width: 266px;
  }
  .countryBanner h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .countryBanner h2 {
    font-size: 15px;
  }
  .countrySearchWrap .form-control {
    height: 37px;
  }
  .countrySearchWrap .btn {
    height: 38px;
  }
  .countryInfoFlex .right {
    width: 200px;
  }

  /* .countryInfoFlex .right img{width:94px;margin:6px 0;} */
  .countryInfoFlex .right::before {
    width: 55px;
    height: 55px;
  }
  .uniRankSec .tableWrap th {
    min-width: 200px;
  }
  .paginationWrap ul li a {
    padding: 3px 9px;
  }
  .courseDetailBanner a img {
    width: 130px;
  }
  .courseDetailBanner a {
    margin-bottom: 10px;
  }
  .appProcessFlex {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .appProcessBox {
    width: 175px;
    margin: 10px;
  }
  .counselWorkFlex {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .counselWorkBox {
    width: 165px;
    margin: 10px;
  }
  .counselWorkBox:before {
    display: none;
  }
  .helpSection {
    height: 220px;
  }
  .allConWrap .countryBox {
    width: 33.32%;
  }
  .fooTopFlex .right h6:nth-of-type(4),
  .fooTopFlex .right ul:nth-of-type(4),
  .fooTopFlex .right h6:nth-of-type(3),
  .fooTopFlex .right ul:nth-of-type(3) {
    display: none;
  }
  .fooTopFlex .right {
    margin-right: 30px;
  }
  iframe.map {
    min-height: 300px;
  }
  .scholarshipSec p,
  .locationSec p {
    margin-bottom: 35px;
    font-size: 16px;
  }
  .scholarshipTop .scolarshipCard.flip-card {
    height: 225px !important;
  }
  .scholarshipTop .scolarshipCard .flip-card-front h2 {
    font-size: 21px;
  }
  .scholarshipBottom .scolarshipCard:nth-last-of-type(1) {
    display: none;
  }
  .scolarshipCard .flip-card-front h2 {
    padding: 10px;
  }
  .leadingWorldSec .slick-current .leadBox,
  .leadBox {
    width: 175px;
    height: 175px;
    margin: 0 auto;
  }
  .leadBox img {
    width: 100px;
  }
  .leadingWorldSec h2 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .leadingWorldSec {
    padding: 35px 0;
  }
  .futureLeadSec {
    padding: 35px 0;
    flex-direction: column;
  }
  .futureLeadSec .left {
    width: 500px;
    margin-bottom: 20px;
    text-align: center;
  }
  .futureLeadSec .right {
    width: 500px;
  }
  .futureLeadSec .left img {
    display: none;
  }
  .studyAbroadFlex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .studyAbroadBox {
    width: 175px;
    margin: 0 5px;
  }
  .studyAbroadBox h6 {
    line-height: 20px;
    font-size: 14px;
  }
  .studyAbroadBox > div {
    height: 95px;
  }
  .studyAbroadBox > div img {
    width: 60px;
  }
  .counselFlex {
    justify-content: center;
    flex-wrap: wrap;
  }
  .counselBox {
    width: 185px;
    margin: 10px 10px;
  }
  .counselSec {
    padding: 30px 0;
  }
  .counselSec h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .futureLeadSec .logoBox {
    height: 180px;
  }

  /* .topRankedSec .logoBox:nth-last-of-type(1),.topRankedSec .logoBox:nth-last-of-type(2){display:none;} */
  .topRankedLogoFlex > div.logoFlex {
    display: none;
  }
  .topRankedLogoFlex .topRankBtn {
    width: auto;
    background: transparent;
    margin-bottom: 0;
  }
  .studyAbroadFlex:before {
    display: none;
  }
  .topRankedLogoFlex {
    justify-content: center;
  }
  .topRankedSec .logoBox {
    width: 30%;
    height: 165px;
  }
  .scholarDetailCont h1 {
    font-size: 21px;
    margin-bottom: 20px;
  }
  .scholarDetailCont h4 {
    font-size: 16px;
  }
  .scholValueBox {
    padding: 15px;
    margin-bottom: 30px;
  }
  .tableWrap > h6 {
    padding: 12px 20px;
    font-size: 16px;
  }
  .greyCampWrap {
    padding: 20px;
    margin-bottom: 30px;
  }
  .greyCampFlex h5 {
    font-size: 16px;
  }
  .inquiryWrap {
    flex-direction: column;
  }
  .inquiryWrap > div {
    width: 100%;
  }
  .inquiryWrap .left {
    position: relative;
    background: #4d3374;
    padding: 30px 50px 0;
  }
  .inquiryWrap .inquiryVector {
    display: none;
  }
  .inquiryWrap .left:before,
  .inquiryWrap .left img {
    display: none;
  }
  .inquiryWrap .left .content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    padding: 0;
  }
  .inquiryWrap .left .content h5 {
    font-size: 20px;
  }
  .inquiryWrap .left .content p {
    margin-bottom: 0;
  }
  .reviewFlex .left {
    width: 300px;
  }
  .reviewFlex .right {
    flex: 1 1 auto;
    width: auto;
  }
  .uniGroupFilters .form-control {
    width: 30%;
  }
  .uniGroupBox {
    width: 216px;
  }
  .uniSliderWrap h5 {
    font-size: 19px;
  }
  .uniSliderWrap {
    padding: 35px 0;
  }
  .countryBanner .logo {
    width: 85px;
  }
  .allGroupsPage .logoBox {
    width: 210px;
    height: 170px;
  }
  .allCountTable {
    display: none;
  }
  .allConWrap {
    display: flex;
  }
  .scholarshipSec h2,
  .locationSec h5,
  .studyAbroadSec h5 {
    font-size: 22px;
  }
  .futureLeadSec .left h5 {
    font-size: 20px;
    line-height: 26px;
  }
  .topRankedSec h5 {
    line-height: 29px;
    font-size: 19px;
  }
  .compareModal .modal-xl {
    max-width: 640px;
  }
  .compareModal .sidebar {
    width: 100%;
  }
  .compareModal .modal-title {
    font-size: 16px;
  }
  .contactWrapper {
    padding: 50px 50px;
  }
  .staticHeader h6 {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .staticPage h4 {
    font-size: 16px;
  }
  .careerWrapper h4 {
    font-size: 18px;
  }
  .careerTop h5 {
    font-size: 16px;
  }
  .careerValueFlex {
    justify-content: center;
    flex-wrap: wrap;
  }
  .careerValueFlex .box {
    width: 300px;
    margin: 0 15px 30px;
  }
  .accBtn img {
    width: 29px;
  }
  .visionFlex .left {
    padding: 20px 25px;
  }
  .visionFlex .right {
    padding: 40px 25px 20px;
  }
  .visionFlex h6 {
    font-size: 19px;
  }
  .visionFlex .left img {
    width: 42px;
  }
  .visionFlex h5 {
    font-size: 16px;
  }
  .visionFlex h5 img {
    width: 14px;
  }
  .careerWrapper h4,
  .marketingWrapper h4 {
    font-size: 19px;
    margin-bottom: 25px;
  }
  .marketingWrapper p {
    font-size: 14px;
    line-height: 24px;
  }
  .marketingWrapper h5 {
    font-size: 16px;
  }
  .marketingFlex .box {
    width: 199px;
    margin: 10px;
    height: 190px;
    padding: 30px 15px 20px;
  }
  .marketingFlex .box img {
    width: 50px;
  }
  .marketingFlex .box p {
    font-size: 14px;
  }
  .marketingBottom h5 {
    font-size: 20px;
  }
  .marketingBottom {
    padding: 35px 0;
  }
  .blogDetailCont h1 {
    font-size: 24px;
  }
  .authorInfo > img {
    width: 100px;
    height: 100px;
  }
  .compareFilters .dropdown {
    width: 29.5%;
  }
  .compareFilters .dropdown .btn {
    font-size: 14px;
  }
  .compareCourseTop h5 {
    font-size: 21px;
  }
  .compareCourseTop .btnGroup .btn {
    width: 190px;
    margin: 0 10px;
  }
  .compareCourseWrap td {
    border-left: 10px solid #fff;
  }
  .headTopNav ul li a,
  .headTopNav ul li button {
    font-size: 12px;
  }
  .regionModal .modal-dialog {
    max-width: 680px;
  }
  .regionFlex h5 {
    font-size: 15px;
  }
  .regionFlex .right ul li {
    width: 42%;
  }
  .countrySearchWrap {
    max-width: 460px;
  }
  .regionModal .countrySearchWrap {
    max-width: 260px;
  }
  .regionFlex.regionCourseFlex .right ul li {
    width: 100%;
  }
  .regionFlex .right ul {
    max-height: 520px;
    overflow-y: scroll;
  }
  .whyFormModal .modal-dialog {
    max-width: 700px;
  }
  .formSection {
    flex-direction: column;
  }
  .formSide {
    padding: 0 0 20px;
    margin: 0 0 20px;
    border-bottom: 1px solid #707070;
    border-right: 0px;
  }
  .whyRegSide,
  .whyRegSide.signupForm {
    width: 100%;
  }
  .whyBoxWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .whyBoxWrap > div {
    width: 49%;
    align-self: stretch;
  }
  .editModal .modal-dialog {
    max-width: 700px;
  }
  .disInfoFlex .box {
    padding: 10px 10px;
    width: 32%;
    align-self: stretch;
  }
  .courseDetailBanner h1 {
    font-size: 17px;
  }
  .courseDetailBanner p {
    font-size: 14px;
  }
  .courseDetailBanner {
    height: 200px;
  }
  .scholarBox {
    width: 216px;
  }
  .fullWidth .scholarBox {
    width: 216px;
  }
  .filterNavBtn {
    display: inline-flex;
    align-items: center;
  }
  .filterNavBtn img {
    width: 15px;
  }
  .sharePgBtn img {
    width: 13px;
  }
  .webFilter {
    display: none;
  }
  .filterSidebar .top {
    top: -50px;
  }
  .fullWidth .courseFlex {
    margin: 0 auto;
    width: 100%;
  }
  .fullWidth .courseBox {
    width: 210px;
  }
  .countrySort .btn {
    font-size: 14px;
    margin-left: 10px;
  }
  .countrySort .btn img {
    width: 14px;
  }
  .customTabs .react-tabs__tab {
    font-size: 13px;
    padding: 6px 10px;
  }
  .coursesModal .modal-dialog {
    max-width: 610px;
  }
  .uniCheckArea .customCheckbox {
    font-size: 13px;
  }
  .uniCheckArea .customCheckbox input[type="checkbox"],
  .uniCheckArea .customCheckbox input[type="checkbox"]::before {
    width: 13px;
    height: 13px;
  }
}
@media (max-width: 767px) {
  p {
    font-size: 13px;
    line-height: 19px;
  }
  h3 {
    font-size: 18px;
  }
  .searchBtn {
    width: 100%;
  }
  .searchWrap .searchBtn .btn {
    border-radius: 5px;
    width: 100%;
    background: #fab316;
    color: #fff;
  }
  .homePage .courseBanner {
    height: auto;
  }
  .headTop {
    height: 60px;
  }
  .tableWrap .table td {
    font-size: 13px;
  }
  .tableWrap .table td,
  .tableWrap .table th {
    padding: 0.7rem;
  }
  .searchWrap {
    flex-direction: column;
    width: 290px;
    margin: 0 auto;
    background-color: transparent;
  }
  .advFilter {
    width: 290px;
  }
  .searchBox {
    width: 100%;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 2px;
  }
  .courseBanner h1 {
    font-size: 18px;
    line-height: 25px;
  }
  .courseBanner h1 span {
    font-size: 16px;
  }
  .courseBanner h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .searchWrap .btn {
    font-size: 16px;
    background: #fff;
    color: #3f2566;
    font-weight: 500;
    border-radius: 2px;
  }
  .searchWrap .btn span {
    display: inline-block;
  }
  .searchWrap .btn .fa {
    display: none;
  }
  .searchWrap .btn img {
    display: none;
  }
  .courseFlex {
    width: 510px;
  }
  .courseBox {
    margin: 5px;
    width: 245px;
  }
  .disciplineFlex .left a {
    background: #fff;
    border-radius: 5px;
  }
  .disciplineBanner {
    height: auto;
  }
  .disciplineFlex .left a img {
    width: 130px;
  }
  .disciplineFlex .uniBannerBtnGroup {
    text-align: center;
  }
  .disciplineBanner > img {
    display: none;
  }
  .disciplineFlex {
    top: 0;
    left: 0;
    transform: none;
    position: relative;
    background: #3f2566;
    padding: 15px;
    flex-direction: column;
  }
  .disciplineFlex .left {
    width: 90%;
    text-align: center;
  }
  .disciplineFlex .right {
    width: 90%;
  }
  .twelveFlex {
    width: 510px;
  }
  .twelveBox .imgWrap {
    height: 144px;
  }
  .twelveBox {
    width: 235px;
  }
  .countryBanner .content {
    position: relative;
    top: 0;
    transform: none;
    left: 0;
    width: 100%;
    padding: 30px 15px;
    background: #3f2566;
    text-align: center;
    min-width: auto;
  }
  .countryInfoFlex {
    flex-direction: column;
  }
  .countryInfoFlex .right {
    order: 1;
    margin-bottom: 15px;
  }
  .countryInfoFlex .left {
    order: 2;
    padding-right: 0px;
  }
  .countryBanner h1 {
    font-size: 17px;
    margin-bottom: 10px;
    line-height: 24px;
  }
  .countrySearchWrap {
    max-width: 410px;
  }
  .countrySearchWrap .form-control {
    height: 34px;
    padding: 7px 20px 7px 28px;
    font-size: 13px;
  }
  .countrySearchWrap .fa {
    left: 9px;
    font-size: 15px;
  }
  .countrySearchWrap .btn {
    height: 34px;
    padding: 5px 15px;
  }
  .tableWrap > h6 {
    padding: 11px 15px;
    font-size: 16px;
  }
  .uniSliderWrap h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .helpSection h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  .helpSection {
    height: 180px;
  }
  .appProcessSec h5 {
    margin-bottom: 15px;
  }
  .allConWrap .countryBox {
    padding: 10px;
  }
  .allConWrap .countryBox a {
    font-size: 13px;
  }
  .allCountriesBanner .content {
    width: 100%;
    padding: 20px;
  }
  .allCountriesBanner h1 {
    font-size: 18px;
  }
  .countryBanner > img {
    display: none;
  }
  .scholarFlex {
    margin: 0 -5px;
  }
  .scholarBox {
    width: 250px;
    margin: 5px;
  }
  .fooTopFlex .right h6:nth-of-type(2),
  .fooTopFlex .right ul:nth-of-type(2) {
    display: none;
  }
  .fooLogo img {
    width: 160px;
  }
  .headTopFlex {
    justify-content: flex-start;
  }
  .headBottom .right {
    position: absolute;
    right: 0px;
    top: -50px;
  }
  .headBottom .right .btn {
    background-color: #fff;
    color: #3f2566;
  }
  .headBottom .middle {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .courseBanner .content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    background: #3f2566;
    padding: 35px 20px;
  }
  .courseBanner > img {
    display: none;
  }
  .courseDetailBanner h1 {
    font-size: 16px;
  }
  .scholarshipTop .scolarshipCard:nth-of-type(2) {
    display: none;
  }
  .scholarshipTop .scolarshipCard.flip-card {
    height: 310px !important;
    width: 100% !important;
  }
  .scholarshipBottom .scolarshipCard:nth-last-of-type(2) {
    display: none;
  }
  .leadingWorldSec .slick-current .leadBox,
  .leadBox {
    width: 195px;
    height: 175px;
  }
  .topRankedSec .logoBox {
    width: 48%;
    height: 165px;
  }
  .scholarDetailCont h1 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .greyCampFlex img {
    width: 80px;
  }
  .greyCampWrap {
    padding: 15px;
  }
  .shareReviewWrap {
    padding: 35px 0;
  }
  .reviewFlex {
    flex-wrap: wrap;
  }
  .reviewFlex .left {
    width: 100%;
  }
  .reviewFlex .right {
    padding-left: 0;
  }
  .shareReviewWrap h5 {
    text-align: left;
    font-size: 18px;
  }
  .sharePageFlex {
    padding: 10px 0;
  }
  .sharePageFlex p {
    font-size: 15px;
  }
  .sharePageFlex ul li a img {
    width: 25px;
  }
  .fooTopFlex .right h6 {
    font-size: 14px;
  }
  .fooTopFlex .right ul li a,
  .fooTopFlex .right ul li button {
    font-size: 12px;
  }
  .fooTopFlex .left ul li a,
  .fooTopFlex .left ul li button {
    font-size: 13px;
  }
  .uniGroupFilters .form-control {
    width: 29%;
  }
  .disciplineInfoBanner {
    margin-bottom: 30px;
  }
  .uniGroupTop h2 {
    margin-bottom: 20px;
  }
  .disciplineInfoBanner h1 {
    font-size: 20px;
  }
  .disciplineInfoBanner h2 {
    font-size: 18px;
  }
  .uniGroupFilters {
    margin-bottom: 20px;
  }
  .allGroupBanner h1 {
    font-size: 19px;
  }
  .allGroupBanner h2 {
    font-size: 18px;
  }
  .compareCourseWrap td {
    width: auto;
  }
  .uniGroupBox {
    width: 245px;
  }
  .scholarshipSec h2,
  .locationSec h5,
  .studyAbroadSec h5 {
    font-size: 19px;
  }
  .leadingWorldSec h1 {
    font-size: 18px;
  }
  .leadingWorldSec h2 {
    font-size: 18px;
    line-height: 29px;
  }
  .futureLeadSec .left h5 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .topRankedSec h5 {
    line-height: 26px;
    font-size: 18px;
  }
  .topRankedSec .logoBox:nth-last-of-type(2) {
    display: none;
  }
  .compareModal .modal-xl {
    max-width: 540px;
  }
  .compareModal .mainContent,
  .compareModal .courseFlex {
    width: 100%;
  }
  .compareModal .courseBox {
    width: 228px;
  }
  .compareModal .courseBox .imgWrap {
    height: 121px;
  }
  .contactWrapper {
    padding: 35px 25px;
  }
  .contactWrapper .form-group {
    margin-bottom: 20px;
  }
  .contactWrapper label {
    font-size: 13px;
  }
  .contactWrapper label img {
    width: 17px;
  }
  .contactWrapper .form-control {
    height: 40px;
    padding: 10px;
  }
  .contactWrapper textarea.form-control {
    height: 130px;
  }
  .contactWrapper .customCheckbox span {
    font-size: 12px;
  }
  .contactWrapper .formBtn {
    width: 170px;
    padding: 7px 10px;
  }
  .careerWrapper h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .careerTop h5 {
    font-size: 15px;
  }
  .staticPage ul li {
    font-size: 13px;
  }
  .staticPage h4 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .staticPage h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .accBtn span {
    color: #fff;
  }
  .accBtn img {
    width: 25px;
  }
  .topSearch .button {
    background: #fff;
    width: 35px;
    height: 35px;
  }
  .searchBox:nth-last-of-type(1) {
    border-right: 1px solid #fff;
  }
  .dropInner .searchWrap {
    flex-direction: row;
  }
  .dropInner .searchWrap button {
    padding: 7px 10px;
  }
  .visionFlex {
    flex-direction: column;
  }
  .visionFlex .left {
    width: 100%;
  }
  .visionFlex .left img {
    bottom: -21px;
    top: auto;
    right: auto;
    transform: translate(-50%, 0);
    left: 50%;
  }
  .compareFilters .dropdown {
    margin: 8px;
  }
  .compareFilters .dropdown .btn {
    padding: 9px 10px;
  }
  .countryFaqs h6 {
    font-size: 17px;
    margin-bottom: 15px;
  }
  .compareCourseTop .btnGroup .btn {
    width: 150px;
  }
  .headTopNav .logo {
    display: block;
    margin: 0 auto 30px;
    width: max-content;
  }
  .closeTopNav,
  .openTopNav {
    display: inline-block;
  }
  .openTopNav {
    margin-top: 8px;
  }
  .headTopNav {
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    padding-top: 50px;
    position: fixed;
    left: -255px;
    top: 0;
    width: 255px;
    height: 100%;
    z-index: 99;
    background-color: #fff;
  }
  .headTopNav.show {
    left: 0;
    box-shadow: 0 0 3px 3px #e0e0e0;
  }
  .headTopNav ul li a,
  .headTopNav ul li button {
    color: #000;
  }

  .headTopNav ul li a img,
  .headTopNav ul li button img {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
  }
  .headTopNav ul li {
    display: block;
    margin: 0;
  }
  .closeTopNav img {
    width: 13px;
  }
  .headTopNav .logo {
    background-color: transparent;
    border: 0;
  }
  .closeTopNav {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .headTopNav ul li a,
  .headTopNav ul li button {
    display: block;
    text-align: left;
    padding: 5px 25px;
    font-size: 15px;
  }
  .headTopNav .sociallinks {
    justify-content: center;
    margin-top: 10px;
  }
  .headTopNav .sociallinks li a,
  .headTopNav .sociallinks li button {
    display: block;
    text-align: left;
    padding: 5px 0px;
    font-size: 15px;
  }
  .regionModal .modal-dialog {
    max-width: 500px;
  }
  .regionFlex .right ul li {
    width: 100%;
  }
  .searchBackWrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .regionModal .backBtn {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
  }
  .regionFlex .left {
    width: 245px;
  }
  .universityList ul li,
  .universityList ul li:nth-of-type(-n + 2) {
    width: 50%;
  }
  .uniListFoo {
    flex-direction: column;
    align-items: flex-end;
  }
  .universityList ul {
    margin: 0 0 10px;
  }
  .uniListFoo p {
    margin-bottom: 5px;
  }
  .universityList h4 {
    padding-right: 35px;
  }
  .whyFormModal .modal-dialog {
    max-width: 500px;
  }
  .signinModal h3 {
    font-size: 20px;
  }
  .eligibilityEducation .flex {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .eligibilityEducation .flex p {
    max-width: 100%;
    margin-right: 0;
  }
  .loginHeadRight .dropdown .btn {
    background: transparent;
    color: #fff;
  }
  .loginHeadRight .headBtn img {
    width: 24px;
  }
  .loginHeadRight .headBtn {
    margin: 0 5px;
  }
  .loginHeadRight .dropdown {
    margin-left: 0;
  }
  .sortFilter {
    flex-direction: column;
  }
  .sortFilter > div:nth-of-type(1) {
    margin-bottom: 10px;
  }
  .sortFilter .primaryBtn {
    font-size: 12px;
    margin-left: 5px;
  }
  .sortFilter .primaryBtn img {
    width: 12px;
  }
  .editModal .modal-dialog {
    max-width: 500px;
  }
  .disInfoFlex .box .btn {
    font-size: 12px;
  }
  .disciplineFlex .right h2 {
    text-align: center;
  }
  .disciplineFlex {
    align-items: center;
  }
  .fullWidth .scholarBox {
    width: 250px;
  }
  .loginHeadRight .dropdown .dropdown-menu {
    right: 33px;
  }
  .fullWidth .courseBox {
    width: 245px;
  }
  .coursesModal .modal-dialog {
    max-width: 545px;
  }
  .modal-title {
    font-size: 16px;
  }
  .sortFilterTop {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mainContTop h5 {
    margin: 0;
    padding-bottom: 10px;
  }
  .sharePageModal .modal-dialog {
    max-width: 520px;
  }
  .shareSearch {
    margin-bottom: 15px;
  }
  .uniCheckArea > div {
    width: 47%;
  }
  .uniIndFilters h6 {
    margin-right: 20px;
    font-size: 14px;
  }
}
.phon {
  color: #d8d6cc;
}

/* @media (max-width:767px){
.main-menu-div{text-align:left;}
.body-overlay{display:none;position:fixed;height:100%;width:100%;left:0;top:0;background:rgba(0,0,0,.25);cursor:pointer}
.main-menu-nav{position:relative;z-index:99;margin-top:0;}
.main-menu-nav .menu-toggler{display:block;width:25px;height:20px;position:relative;border-bottom:2px solid #000;float:right;z-index:1;margin:0;transition:all 0.3s linear}
.topBar.darkHeader .main-menu-nav .menu-toggler{width:22px;height:16px;top:16px;right:4px;}
.main-menu-nav .menu-toggler::before,.main-menu-nav .menu-toggler::after{content:"";position:absolute;left:0;top:0;right:0;width:100%;height:2px;background:#000}
.main-menu-nav .menu-toggler::after{top:50%;}
.main-menu-nav .menu-toggler.close{border:0 none;transition:all 0.3s linear}
.main-menu-nav .menu-toggler.close::before,.main-menu-nav .menu-toggler.close::after{top:0;transform-origin:0 0}
.main-menu-nav .menu-toggler.close::before{transform:rotate(45deg);left:0}
.main-menu-nav .menu-toggler.close::after{transform:rotate(-48deg);left:0;top:auto;bottom:0}
.main-menu-nav .main-menu-div{text-align:left;position:fixed;left:-400px;top:0;height:100%;width:210px;background:#291C5A;box-shadow:0 0 5px 2px rgba(0,0,0,0.1),0 0 5px 3px rgba(0,0,0,0.3);transition:all 0.3s linear;z-index:2;overflow:auto}
.main-menu-nav .main-menu-div.mobiel-menu{left:0;}
.main-menu-nav .main-menu-div ul{visibility:visible;opacity:1}
.main-menu-nav .main-menu-div ul li{display:block;height:auto;margin:0;}
.main-menu-nav .main-menu-div ul li a{color:#fff!important;display:block;padding:10px 20px;border-bottom:1px solid rgba(255,255,255,0.25);line-height:1.4;font-size:14px;}
.main-menu-nav .main-menu-div ul li.active a,.main-menu-nav .main-menu-div ul li:hover a{background:linear-gradient(to bottom,#2889B5,#0B628B);}
.main-menu-nav .main-menu-div ul li ul{min-width:280px;border:none;top:0;position:relative;padding:0;margin:0;width:100%;background:#021641;display:none;border-radius:0;}
.main-menu-nav .main-menu-div>ul>li>ul.open>li{height:auto}
.main-menu-nav .main-menu-div ul li ul li a{padding-left:30px;font-weight:400;color:#ffffff}
.main-menu-div>ul>li>ul>li:hover,.main-menu-div>ul>li>ul>li>ul>li:hover{background:#021641}
.main-menu-nav .main-menu-div>ul>li>ul>li>ul{left:auto;top:auto;background:#021641}
.main-menu-nav .main-menu-div>ul>li>ul>li>ul.open li{height:auto}
.main-menu-nav .main-menu-div>ul>li>ul>li>ul>li>a{border-bottom-color:rgba(255,255,255,0.1)}
.main-menu-nav .menu-header{display:block;padding:20px;border-bottom:1px solid rgba(255,255,255,0.25)}
.main-menu-nav .menu-header::before,.main-menu-nav .menu-header::after{content:"";display:table;clear:both}
.menu-header .home-icon{float:left;display:block;position:relative;z-index:2;color:#ffffff;font-size:20px;cursor:pointer}
.menu-header .home-icon::before{margin:0;position:relative;z-index:-1}
.menu-header .close-icon{display:block;float:right;cursor:pointer;color:#ffffff;font-size:20px;cursor:pointer}
.menu-header .close-icon::before{margin:0}
.main-menu-nav .down-arrow{position:absolute;top:0;right:0;width:40px;height:39px;border-left:1px solid rgba(255,255,255,0.25);cursor:pointer;z-index:1}
.main-menu-nav .down-arrow::after{content:"";position:absolute;right:50%;top:19px;margin-top:-3px;margin-right:-3px;height:0;width:0;border-top:5px solid #fff;border-left:5px solid transparent;border-right:5px solid transparent;transition:all 0.3s linear}
.main-menu-nav .submenu-opened.down-arrow::after{border-top:0 solid transparent;border-bottom:5px solid #fff}
.main-menu-div>ul>li.hovered>ul>li.hovered>ul{top:0;}
.main-menu-div>ul>li>ul:before{display:none;}
.top-bar.yes-mobile .main-menu-div ul li a{line-height:40px;font-size:14px;padding:0 15px;}
.headBottom .middle{display:block;position:absolute;transform:none;top:38%;left:auto;right:178px;}
.main-menu-div>ul>li>a img{display:inline-block;margin:0 15px 0 0;}
} */
@media (max-width: 575px) {
  .locationSec .locationDropdown input.form-control {
    height: 40px;
  }
  .eligibility-form-control.form-control {
    max-width: 100%;
  }
  .courseFlex {
    width: 100%;
    justify-content: center;
  }
  .courseBox {
    width: 290px;
    margin: 5px auto;
  }
  .courseDetailBanner {
    height: auto;
  }
  .courseDetailBanner a {
    margin-right: 0;
    background: #fff;
    border-radius: 5px;
  }
  .courseDetailBanner .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    top: 0;
    transform: none;
    left: 0;
    background: #3f2566;
  }
  .courseDetailBanner > img {
    display: none;
  }
  .header .logo img {
    width: 185px;
  }
  .main-menu-div > ul > li > a,
  .main-menu-div > ul > li > button {
    font-size: 12px;
  }
  .searchBox .css-1wa3eu0-placeholder,
  .css-b8ldur-Input input {
    font-size: 12px;
  }
  .courseBanner h1 {
    font-size: 20px;
  }
  .courseBanner h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .mainContTop h5 {
    font-size: 14px;
    line-height: 20px;
  }
  .uniSliderWrap .slick-slider {
    width: 275px;
    margin: 0 auto;
  }
  .uniSliderWrap .slick-prev {
    left: -45px;
  }
  .uniSliderWrap .slick-next {
    right: -45px;
  }
  iframe.map {
    min-height: 300px;
  }

  /* .uniSliderWrap h5{font-size:20px;margin-bottom:20px;} */
  .uniSliderWrap {
    padding: 30px 0;
  }
  .twelveFlex {
    width: 100%;
    justify-content: center;
  }
  .allConWrap .countryBox {
    width: 49.32%;
  }
  .allCountriesBanner {
    margin-bottom: 30px;
  }
  .scholarFlex {
    justify-content: center;
  }
  .scholarBox {
    width: 262px;
  }
  .fooTopFlex {
    flex-direction: column;
  }
  .fooTopFlex .left {
    margin-bottom: 20px;
    order: unset;
  }
  .fooTopFlex .right {
    margin-right: 0;
  }
  .fooTopFlex .right ul:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  .courseBanner h1 {
    font-size: 17px;
    font-weight: 500;
  }
  .courseBanner h1 span {
    font-size: inherit;
    font-weight: inherit;
    display: inline;
    margin-left: 5px;
  }
  .leadingWorldSec .slick-slider {
    width: 85%;
    margin: 0 auto;
  }
  .scholarshipBottom .scolarshipCard:nth-last-of-type(4) {
    display: none;
  }
  .courseBanner {
    margin-bottom: 30px;
  }
  .scholarshipSec h2,
  .locationSec h5,
  .studyAbroadSec h5 {
    margin-bottom: 5px;
    font-size: 17px;
  }
  .scholarshipSec p,
  .locationSec p {
    margin-bottom: 25px;
    font-size: 15px;
  }
  .leadingWorldSec h1 {
    font-size: 18px;
  }
  .leadingWorldSec h2 {
    font-size: 17px;
  }
  .futureLeadSec .left,
  .futureLeadSec .right {
    width: 100%;
  }
  .counselSec h5 {
    font-size: 17px;
  }
  .counselBox img {
    width: 80px;
  }
  .shareReviewWrap h5 {
    font-size: 16px;
  }
  .scholarDetailCont h4 {
    font-size: 15px;
  }
  .scholarDetailCont ul li {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .greyCampFlex {
    align-items: flex-start;
  }
  .greyCampFlex img {
    width: 60px;
  }
  .greyCampFlex div {
    padding-left: 15px;
  }
  .greyCampFlex h5 {
    font-size: 15px;
  }
  .uniGroupFilters .form-control {
    width: 45.5%;
  }
  .allGroupBanner > img {
    display: none;
  }
  .allGroupBanner .content {
    width: 100%;
    padding: 20px;
    background-color: #3f2566;
    text-align: center;
    position: relative;
    transform: none;
    top: 0;
    left: 0;
  }
  .allGroupsPage .logoBox {
    width: 205px;
  }
  .allGroupBanner {
    margin-bottom: 30px;
  }
  .allGroupBanner h1 {
    font-size: 17px;
  }
  .allGroupBanner h2 {
    font-size: 16px;
  }
  .uniGroupBox {
    width: 263px;
  }
  .modal-dialog {
    margin: 1rem auto;
  }
  .compareModal .modal-xl {
    max-width: 430px;
  }
  .countryBanner h2 {
    font-size: 14px;
  }
  .careerWrapper h4,
  .marketingWrapper h4 {
    font-size: 17px;
  }
  .marketingBottom h5 {
    font-size: 17px;
  }
  .marketingBottom p {
    font-size: 14px;
    line-height: 22px;
  }
  .blogDetailCont img {
    width: 210px;
  }
  .compareCourseTop .btnGroup .btn {
    width: 135px;
    margin: 0 5px;
  }
  .signinModal .modal-dialog {
    max-width: 400px;
  }
  .regionModal .modal-dialog {
    max-width: 460px;
  }
  .regionFlex ul li a {
    font-size: 13px;
  }
  .editModal .btnGroup .btn {
    width: 135px;
    margin: 10px 5px;
  }
  .signinModal .modal-body {
    padding: 50px 20px 20px;
  }
  .whyBoxWrap > div {
    width: 100%;
    padding: 10px 10px 5px;
    margin-bottom: 10px;
  }
  .whyBoxWrap h6 {
    font-size: 12px;
    line-height: 18px;
  }
  .whyBoxWrap img {
    margin-bottom: 5px;
  }
  .signinModal .formBtn {
    width: 190px;
    font-size: 14px;
    padding: 7px 10px;
  }
  .signinModal .formBtn1 {
    width: 190px;
    font-size: 14px;
    padding: 7px 10px;
  }
  .signinModal .form-group label {
    font-size: 13px;
  }
  .form-control {
    height: 40px;
    font-size: 13px;
  }
  .css-1hvq1u5-control,
  .css-1r4d67j-control,
  .css-yk16xz-control,
  .css-1pahdxg-control {
    height: 40px;
  }
  .css-1wa3eu0-placeholder {
    font-size: 13px;
  }
  .react-tel-input .form-control {
    height: 30px !important;
    font-size: 13px;
  }
  .formIcon {
    left: 10px;
    top: 11px;
    width: 16px;
  }
  .signinModal h3 {
    text-transform: capitalize;
    font-weight: 600;
  }
  .signinModal ul li a img {
    width: 40px;
  }
  .signinModal ul li {
    margin: 0 5px;
  }
  .signinModal .orP {
    margin: 0 auto 10px;
  }
  .signinModal .orP::before {
    left: -100px;
    width: 100px;
  }
  .signinModal .orP::after {
    right: -100px;
    width: 100px;
  }
  .otpWrap input {
    width: 40px !important;
    height: 30px;
  }
  .sortFilterTop {
    flex-direction: column;
    text-align: center;
  }
  .sortFilterTop h5 {
    margin-right: 0;
  }
  .sortFilterTop .customCheckbox {
    margin-bottom: 10px;
  }
  .countrySort .btn {
    font-size: 13px;
  }
  .uniGroupFlex {
    justify-content: center;
  }
  .coursesModal .modal-dialog {
    max-width: 420px;
  }
  .modal-title {
    font-size: 15px;
    line-height: 1.2;
  }
  .shareSearch .btn {
    height: 40px;
  }
  .sharePageModal.signinModal h3 {
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  a {
    word-break: break-all;
    font-size: 14px;
  }
  .form-control {
    font-size: 12px;
    height: 40px;
  }
  textarea.form-control {
    height: 100px;
  }
  .customCheckbox {
    font-size: 12px;
  }
  .css-1wa3eu0-placeholder {
    font-size: 12px;
  }
  .css-ky9w75-singleValue,
  .MuiInputBase-input {
    font-size: 12px !important;
  }
  .css-1hvq1u5-control,
  .css-1r4d67j-control {
    height: 40px;
  }
  .MuiFormControl-marginNormal {
    height: 40px;
  }
  .formBtn {
    width: 230px;
    padding: 8px 10px;
  }
  .signinModal .modal-body {
    padding: 30px 15px 20px;
  }
  .signinModal .modal-dialog {
    max-width: 300px;
  }
  .signinModal ul li a img {
    width: 40px;
  }
  .signinModal ul li {
    margin: 0 5px;
  }
  .signinModal ul {
    margin: 0 0 15px;
  }
  .orP {
    margin: 0 auto 15px;
  }
  .orP::before {
    left: -90px;
    width: 80px;
    height: 1px;
  }
  .orP::after {
    right: -90px;
    width: 80px;
    height: 1px;
  }
  .signinModal h3 {
    font-size: 18px;
  }
  .signinModal .close img {
    width: 12px;
  }
  .signinModal .close {
    top: 9px;
    right: 12px;
  }
  .mainContTop {
    flex-direction: column;
  }
  .disciplineFlex .left p {
    display: none;
  }
  .factBox {
    width: 100%;
  }
  .factBox img {
    display: inline-block;
  }
  .factBox div {
    padding-left: 15px;
  }
  .disciplineFlex .left h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .uniSliderWrap .slick-next {
    right: -5px;
    background: #fff;
  }
  .uniSliderWrap .slick-prev {
    left: -5px;
    background: #fff;
    z-index: 99;
  }
  .uniSliderWrap h5 {
    font-size: 16px;
  }
  .disciplineInfoBanner h1 {
    font-size: 18px;
  }
  .disciplineInfoBanner h2 {
    font-size: 16px;
  }
  .countryInfoFlex .left {
    text-align: center;
  }
  .disciplineInfoBanner p {
    text-align: justify;
    font-size: 12px;
  }
  .disInfoFlex {
    flex-wrap: wrap;
  }
  .disInfoFlex .box {
    width: 200px;
    margin: 0 auto 10px;
  }
  .allConWrap .countryBox {
    width: 100%;
  }
  .allCountriesBanner h1 {
    font-size: 16px;
  }
  .headBottom {
    flex-direction: column;
  }
  .headBottom a.logo {
    margin-bottom: 15px;
  }
  ul.socialIcons li {
    margin-left: 21px;
  }
  ul.socialIcons li a:after {
    left: -13px;
  }
  .headBottom .right .btn {
    width: 72px;
    padding: 2px 10px;
  }
  .studyAbroadSec h5 {
    font-size: 15px;
    line-height: 20px;
  }
  .futureLeadSec .left h5 {
    font-size: 16px;
  }
  .scholarshipTop {
    display: none;
  }
  .scholarshipBottom .scolarshipCard {
    width: 205px;
    margin: 10px 10px;
  }
  .scholarshipBottom {
    flex-wrap: wrap;
    justify-content: center;
  }
  .scholarshipSec {
    text-align: center;
    margin-bottom: 30px;
  }
  .locationSec {
    margin-bottom: 30px;
  }
  .leadingWorldSec .slick-current .leadBox,
  .leadBox {
    width: 140px;
    height: 145px;
  }
  .logoFlex {
    width: 300px;
    justify-content: center;
    margin: 0 auto;
  }
  .futureLeadSec .logoBox {
    height: 145px;
    width: 215px;
  }
  .topRankedSec .logoBox {
    width: 200px;
    height: 140px;
  }
  .studyAbroadBox h6 {
    line-height: 16px;
    font-size: 12px;
  }
  .counselBox h6 {
    font-size: 13px;
  }
  .scholarDetailCont h1 {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 20px;
  }
  .tableWrap > h6 {
    font-size: 15px;
  }
  .greyCampFlex {
    flex-direction: column;
  }
  .greyCampFlex img {
    margin-bottom: 15px;
  }
  .greyCampFlex div {
    padding-left: 0;
  }
  .inquiryWrap .left {
    padding: 30px 15px 0;
  }
  .inquiryWrap .right {
    padding: 30px 15px;
  }
  .inquiryWrap .left .content h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .uniGroupFilters .form-control {
    width: 200px;
    margin: 0 auto 10px;
  }
  .uniGroupTop h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .disciplineInfoBanner {
    margin-bottom: 20px;
  }
  .compareModal .modal-xl {
    max-width: 90%;
  }
  .compareModal .modal-title {
    font-size: 14px;
  }
  .staticHeader h6 {
    font-size: 14px;
    line-height: 22px;
  }
  .careerTop h5 {
    font-size: 14px;
  }
  .careerTop {
    margin-bottom: 50px;
  }
  .careerValueFlex .box {
    width: 260px;
  }

  /* .topSearch .dropdown-menu{width: 240px;} */
  .dropInner .searchWrap {
    width: 218px;
  }
  .searchSecBox ul li a {
    font-size: 13px;
    line-height: 18px;
  }
  .searchSecBox p.tag {
    font-size: 12px;
  }
  .searchSecBox a.showBtn {
    font-size: 13px;
  }
  .gridMap .btn {
    margin: 5px 10px;
    width: 155px;
  }
  .searchWrap {
    width: 280px;
  }
  .compareFilters .dropdown {
    width: 46.5%;
    margin: 4px;
  }
  .compareCourseTop h5 {
    font-size: 18px;
  }
  .compareCourseTop h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .countryFaqs .accordion .btn {
    padding: 10px 10px;
  }
  .countryFaqs .accordText {
    padding: 0 10px 10px;
  }
  .compareCourseTop .btnGroup .btn {
    width: 130px;
    margin: 5px 5px;
  }
  .regionFlex .left {
    width: 100%;
  }
  .regionFlex {
    flex-wrap: wrap;
  }
  .regionFlex .right h5 {
    padding-left: 0 !important;
  }
  .regionFlex .right ul li {
    margin: 4px 0;
  }
  .regionModal .modal-dialog {
    max-width: 300px;
  }
  .searchBackWrap {
    flex-direction: column;
  }
  .regionModal .backBtn {
    margin-bottom: 10px;
  }

  /* .listToggle{display:none;} */
  .gridMap .btn:nth-of-type(1) {
    display: none;
  }
  .sortFilter {
    justify-content: center;
    flex-wrap: wrap;
  }
  .sortFilter > div {
    margin: 5px;
  }
  .courseBoxCont p.relevantP a {
    font-size: 12px;
  }
  .courseBoxCont .btnGroup .btn {
    padding: 5px 8px;
  }
  .sortFilter .primaryBtn:nth-of-type(1) {
    display: none;
  }
  .signupForm .whyBoxWrap > div {
    width: 100%;
  }
  .disciplineFlex .left a img {
    width: 110px;
  }
  .disciplineFlex .right h2 {
    font-size: 16px;
  }
  .disciplineFlex .right {
    width: 100%;
  }
  .customTabs.countryPageTabs .react-tabs__tab {
    display: block;
  }
  .coursesModal .modal-dialog {
    max-width: 300px;
  }
  .sortFilterTop > div {
    flex-wrap: wrap;
    justify-content: center;
  }
  .popOptWrap {
    width: 100%;
  }
  .signinModal ul li a img {
    width: 30px;
  }
  .uniCheckArea > div {
    width: 100%;
  }
  .sitemapCard h5 {
    line-height: 24px;
  }
  .sitemapCard h6 {
    font-size: 12px;
    line-height: 17px;
  }
  .sitemapCard p {
    font-size: 13px;
  }
}
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a,
.pagination > li > .page-link {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #f9b316 !important;
  border-color: #f9b316 !important;
  outline: none;
  color: #fff;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

/*   auto suggestion */
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  /*width: 150px;*/

  /*height: 30px;*/

  /*padding: 10px 20px;*/

  /*font-family: Helvetica, sans-serif;*/

  /*font-weight: 300;*/
  font-size: 16px;
  color: #ffffff; /*border: 1px solid #aaa;*/ /*border-radius: 4px;*/
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.filterSidebar.v2 .fa-angle-right {
  display: block !important;
}

.discipline-modal.close {
  color: #000;
}

span.searchItemBtn.btn.dropdown-item {
  text-align: left;
  font-size: 14px;
  padding: 2px 10px;
  white-space: break-spaces;
}

.tcf-tag.searchTagWrap {
  top: 6px;
  z-index: 9;
}
.tcf-tag.searchTagWrap .searchTag {
  max-width: 90px;
  height: 25px;
  font-size: 14px;
  background-color: #f6f6f6;
}
.tcf-messenger-widget {
  bottom: 45px;
  z-index: 10;
}

.fb_dialog iframe {
  bottom: 88px !important;
}

.live-chat-loader-placeholder {
  position: absolute !important;
  bottom: -5pt !important;
  right: -2pt !important;
  width: 35pt !important;
  height: 35pt !important;
}

.live-chat-loader-placeholder div {
  width: 48px !important;
  height: 47px !important;
}

.live-chat-loader-placeholder svg {
  height: 25px;
}

.embed-lightbox-popover-iframe.left {
  left: auto !important;
}

.homepage-speeddial {
  background-color: #47305c !important;
}

.embed-lightbox-iframe-popover-right.embed-lightbox-is-showing
  .embed-lightbox-content-holder {
  bottom: 10px !important;
  right: 5px !important;
}

.fb_dialog_content iframe {
  right: 11px;
  width: 40px;
  height: 40px;
}

.fb_dialog_content iframe {
  bottom: 0px;
}

.fb_iframe_widget iframe {
  right: 0px !important;
  right: 0px !important;
}
